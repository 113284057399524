export const API_URL = {
  LOGIN: "/user/login",
  CHANGE_PASSWORD: "/user/changepassword",
  FORGOT_PASSWORD: "/user/forgot-pasword",
  NEW_PASSWORD: "/user/verify-otp",
  CURRENT_USER_DETAILS: "/user/details",
  GET_ALL_KNOWLEDGE: "/knowledgebase/getAllKb",
  GET_ONE_KNOWLEDGE: "/knowledgebase/getKbById",
  CREATE_KNOWLEDGE: "/knowledgebase/create",
  UPLOAD_KNOWLEDGE_FILE: "/knowledgebase/upload",
  CREATE_KNOWLEDGE_WITH_FILE: "/knowledgebase/createtemp",
  UPDATE_KNOWLEDGE_WITH_FILE: "/knowledgebase/edit",
  DELETE_KNOWLEDGE_WITH_FILE: "/knowledgebase/delete",
  DELETE_PROJECT: "/project/delete",
  SEARCH_KNOWLEDGE_DOC: "/knowledgebase/search",
  GET_SUGGETIONS: "/knowledgebase/document/suggesstion",
  ALL_PUBLIC_KB: "/knowledgebase/public",
  SEND_CHAT_MESSAGE: "/knowledgebase/chat",
  FETCH_CHAT_HISTORY: "/knowledgebase/getHistory",
  CLEAR_CHAT_HISTORY: "/knowledgebase/clearhistory",
  CHATBOT_DETAIL: "/chatbot/details",
  UPLOAD_CHATBOT_FILE: "/chatbot/uploadDocument",
  UPLOAD_CHATBOT_URL: "/chatbot/uploadUrl",
  UPDATE_CHATBOT: "/chatbot/UpdateDetails",
  UPLOAD_CHATBOT_LOGO: "/chatbot/UpdateLogo",
  DELETE_CHATBOT_DOC: "/chatbot/document/delete",
  ALL_CONFIGURATION: "/knowledgebase/getAllConfiguration",
  CREATE_INTEGRATION: "/knowledgebase/newconfiguration",
  DELETE_INTEGRATION: "/knowledgebase/deleteConfiguration",
  UPDATE_INTEGRATION: "/knowledgebase/updateToken",
  DELETE_KB_FILE: "/knowledgebase/file/delete",
  AUTH_URL: "/knowledgebase/createAuthURL",
  GOOGLE_FOLDER_LIST: "/knowledgebase/gdriveFolderList",
  GOOGLE_FOLDER_DETAIL_LIST: "/knowledgebase/gdriveFolderFileList",
  NOTION_FOLDER_LIST: "/knowledgebase/notionPageList",
  GET_ALL_WORKSHOP: "/tenant/getWorkSpace",
  GET_ONE_WORKSHOP: "/tenant/workspaceById",
  CREATE_WORKSPACE: "/tenant/createWorkSpace",
  UPDATE_WORKSPACE: "/tenant/editWorkspace",
  WORKSPACE_STATUS_UPDATE: "/tenant/workspaceStatusUpdate",
  USER_STATUS_UPDATE: "/user/updateUserStatus",
  GET_ALL_USERS: "/user/getAllUser",
  GET_ALL_USER_ROLE: "/user/roleList",
  GET_WORKSPACE_LIST: "/user/workspaceList",
  CREATE_USER: "/user/create",
  GET_ONE_USER: "/user/",
  UPDATE_USER: "/user/edit/",
  GET_ALL_CONFIGURE_ACTIONS: "/workflow/action",
  GET_ALL_PROJECT: "/project/",
  CREATE_PROJECT: "/project/create",
  PROJECT_LOCATION_SUGGESSTION: "/project/place-suggesstion",
  UPDATE_PROJECT: "/project/update/",
  GET_ONE_CONFIGURE_ACTIONS: "/workflow/action",
  GET_SELECT_TEMPLATE_LIST_ACTIONS: "/workflow/template/selection",
  GET_SELECT_ACTION_LIST: "/workflow/action/selection",
  CREATE_CONFIGURE_ACTIONS: "/workflow/action/create",
  UPDATE_CONFIGURE_ACTIONS: "/workflow/action/update",
  DELETE_CONFIGURE_ACTION: "/workflow/action/delete",
  ONE_DRIVE_FOLDER_LIST: "/knowledgebase/onedriveFileList",
  ONE_DRIVE_FOLDER_DETAIL_LIST: "/knowledgebase/onedriveFolderFileList",
  GET_ALL_KB: "/user/kbList",
  GET_ALL_WORKFLOW: "/workflow/list",
  CREATE_WORKFLOW: "/workflow/create",
  GET_ONE_WORKFLOW: "/workflow",
  UPDATE_WORKFLOW: "/workflow/update",
  WORKFLOW_STATUS_UPDATE: "/workflow/status",
  WORKFLOW_KB_FILE_LIST: "/workflow/kbfiles",
  WORKFLOW_TEMPLATE_LIST: "/workflow/template/action",
  RUN_WORKFLOW_TEMPLATE_LIST: "/workflow/template/data",
  WORKFLOW_TERMS_RESPONSE: "/workflow/response",
  SAVE_WORKFLOW_RESPONSE: "/workflow/save",
  GET_ALL_TEMPLATELIST: "/workflow/template",
  CREATE_TEMPLATELIST: "/workflow/template/create",
  UPDATE_TEMPLATELIST: "/workflow/template/update",
  GET_ONE_TEMPLATELIST: "/workflow/template",
  TEMPLATELIST_STATUS_UPDATE: "/workflow/template/status",
  GET_DEFAULT_WORKFLOW_ACTION: "/workflow/action/default",
  GET_WORKFLOW_RUN_HISTORY: "/workflow/run/history",
  RUN_WORKFLOW_LATEST: "/workflow/run",
  STORE_WORKFLOW_ACTION: "/workflow/store",
  GET_ALL_ASSISTANTS: "/assistant",
  CREATE_ASSISTANTS: "/assistant/create",
  UPDATE_ASSISTANTS: "/assistant/update",
  DELETE__ASSISTANTS: "/assistant/delete",
  DELETE_PARTICULAR_ACTION: "/workflow/action/delete",
  GET_ALL_KBFILES: "/assistant/kbfiles",
  GET_ALL_DEFAULT_ASSISTANT: "/assistant/curated",
  CREATE_NEW_THRED: "/assistant/thread/create",
  UPDATE_NEW_THRED: "/assistant/thread/update",
  SEND_ASSISTANTS_MESSAGE: "/assistant/chat",
  GET_ALL_THREAD: "/assistant/thread",
  DELETE_THREAD: "/assistant/thread/delete",
  FETCH_THREAD_HISTORY: "/assistant/thread/chat",
  USER_ACTIVITY: "/user/logs",
  SIGNUP: "/user/logs",
  GET_SUBSCRIPTION_PLAN: "/subscription/plan",
  GET_CURRUNT_SUBSCRIPTION_PLAN: "/subscription/current-plan",
  SIGNUP_USER: "/user/signup",
  SIGNUP_USER_OTP_VERIFICATION: "/user/signup-verification",
  GET_HISTORY_SUBSCRIPTION_PLAN: "/subscription/transaction",
  SUBSCRIPTION_PLAN_UPGRADE: "/subscription/upgrade",
  CANCEL_SUBSCRIPTION_PLAN: "/subscription/cancel",
  GET_ALL_TENANT_REPORT: "/project/report/tenant",
  UPDATE_REPORT: "/project/report/update/",
  DELETE_REPORT: "/project/report/delete",
  DASHBOARD_COUNT: "/project/dashboard/count",
  DASHBOARD_REPORT_COUNT: "/project/dashboard/report",
  DASHBOARD_SAFETYBUDDY_COUNT: "/project/dashboard/safetybuddy",
  DASHBOARD_SERVICE_UNIT_COUNT: "/project/dashboard/inspection-count",
  DASHBOARD_AUDIT_COUNT: "/project/dashboard/audit-count",

  DASHBOARD_TODO_LIST: "/project/to-do/list",
  DASHBOARD_TODO: "/project/to-do",
  PROJECT_SELECTION: "/project/selection",
  FORM300: "/project/report/form300",
  FORM301: "/project/report/form301",
  FORM301_BY_REPORTID: "/project/report/form301-prefill",
  GET_ALL_FORM300: "/project/report/form300-list",
  GET_ALL_FORM301: "/project/report/form301-list",
  NOTIFICATION: "/project/report/notifications",
  GET_ALL_NOTIFICATION: "/project/report/notifications/list",
  FORM_BUILDER: "/form-builder/forms",
  FORM_TYPES: "/form-builder/forms/types",
  FORMS: "/forms",
  FORM_SUBTYPES: "/form-builder/forms/subtypes",
  FORM_SUBMISSIONS: "/form-builder/submissions",
  FORM_DOC_ANALYSE: "/form-builder/analyze/doc",
  FEEDBACK: "/project/feedbacks",

  SERVICE_UNIT_TYPE: "/service-unit/types",
  SERVICE_UNIT: "/service-unit",
  SERVICE_UNIT_TASK: "/service-unit/inspection-todos",
  SERVICE_UNIT_INSPECTION_SCHEDULE: "/service-unit/inspection-schedules",
  SERVICE_UNIT_SCHEDULED_INSPECTION: "/service-unit/inspection-todos",
  GENERATE_QR: "/service-unit/getqr",

  AUDIT_TEMPLATES: "/form-builder/form/subtypes/filter/post",
  AUDIT: "/project/report/audit-todos",
  AUDIT_CREATE: "/project/report/assign/audit",
};
