import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import DataGridTable from "../dataGrid";
import moment from "moment";

import SearchIcon from "@mui/icons-material/Search";

import Pencil from "../../../assets/icons/pencil.svg";

import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import {
  deleteFormInstanceById,
  fetchAllFormsInstances,
  getAllForm,
} from "../../../redux/slices/forms.slice";
import { Status } from "../../../utils/icon/CommonFunctions";
import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import CreateFormModal from "../forms/createFormModal";
import EditFormModal from "../forms/editFormModal";
import {
  getAllServiceUnitSchedule,
  getAllServiceUnitTask,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";
import { MdOutlineWatchLater, MdRemoveRedEye } from "react-icons/md";
import { FaEye, FaPlayCircle, FaRegPlayCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import FilterDrawer from "../../layout/filterDrawer";
import { RiFilter2Line } from "react-icons/ri";
import CreateTasks from "./createTask";

const formTypeMapping = {
  employee_induction: "Employee Induction",
  work_permit: "Work Permit",
  inspection: "Inspection",
};

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #036185",
    borderRadius: 8,
    padding: 4,
    boxShadow: "none",

    "&:hover": {
      borderColor: "#036185",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
    overflowY: "auto",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};
export default function ActionList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formHistoryData, setFormHistoryData] = useState([]);
  const [scheduledInspections, setScheduledInspections] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const { serviceUnitId } = useParams();
  const [mode, setMode] = useState("edit");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [paginationModel2, setPaginationModel2] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [sortKey2, setSortKey2] = useState("due_date");

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState();
  const [selectedFormId, setSelectedFormId] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState(false);
  const [selectedInspectionTask, setSelectedInspectionTask] = useState();
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [isOpenEditScheduleDrawer, setIsOpenEditScheduleDrawer] =
    useState(false);
  const [isOpenCreateScheduleDrawer, setIsOpenCreateScheduleDrawer] =
    useState(false);
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [isOpenScheduleInspection, setIsOpenScheduleInspection] =
    useState(false);
  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [severityFilter, setSeverityFilter] = useState({
    value: "All",
    label: "All",
  });
  const [scheduleFilter, setScheduleFilter] = useState({
    value: "All",
    label: "All",
  });
  const handleTypeChange = (selectedOption) => {
    setSelectedForm(selectedOption);
    setError();
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const getAllForms = async () => {
    setLoading(true);

    let custom_filter = {};

    custom_filter.form_type__in = "inspection";

    let response = await dispatch(
      fetchAllFormsInstances({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
        service_unit: serviceUnitId,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setFormHistoryData(payload?.data);

      setTotalRow(payload?.total_object);
    }
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteFormInstanceById({ formId: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        getAllForms();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllForms();
  }, [paginationModel, sortKey]);

  const fetchInspectionForms = async () => {
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ type__in: ["inspection"] }),

          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setForms(
          payload?.data?.map((item) => ({ label: item.title, value: item.id }))
        );
        // if (payload?.data?.length > 0) {
        //   setSelectedForm(
        //     payload?.data?.map((item) => ({
        //       label: item.title,
        //       value: item.id,
        //     }))[0]
        //   );
        // }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
  };
  useEffect(() => {
    fetchInspectionForms();
  }, []);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                // navigate(
                //   `/service-unit/${serviceUnitId}/view/inspection/${params?.id}/view`
                // )
                setMode("view");
                setIsOpenEditDrawer(true);
                setSelectedInspectionTask(params.row);
              }}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                // navigate(
                //   `/service-unit/${serviceUnitId}/view/inspection/${params?.id}/edit`
                // )
                setMode("edit");
                setIsOpenEditDrawer(true);
                setSelectedInspectionTask(params.row);
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const handleSorting2 = (e, key) => {
    if (key) {
      if (!sortKey2) {
        setSortKey2(key);
      } else if (
        sortKey2 &&
        sortKey2.startsWith("-") &&
        sortKey2.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey2(sortKey2.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey2(`-${key}`);
      }
    }
  };

  const getAllInspectionSchedule = async () => {
    let response = await dispatch(
      getAllServiceUnitSchedule({
        page_number: 1,
        service_unit: serviceUnitId,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      if (payload?.data && payload?.data.length > 0) {
        setScheduleOptions([
          { label: "All", value: "All" }, // First option as "All"
          ...payload?.data?.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        ]);
      }
    }
  };

  useEffect(() => {
    getAllInspectionSchedule();
  }, []);
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "formType",
      headerName: "Form Type",
      flex: 2,
      minWidth: 200,
      valueGetter: (params) => formTypeMapping[params.row.form?.type] || "-",

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__type")}
        >
          Form Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "form",
      headerName: "Form Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__title")}
        >
          Form Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: (params) => {
        return <div>{params.value.title}</div>;
      },
    },

    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 140, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      minWidth: 130,
      renderCell: ActionCell,
    },
  ];

  const TabPanel = ({ children, value, index, ...other }) => (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );

  const isDueDatePast = (dueDate) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    const due = new Date(dueDate).toISOString().split("T")[0]; // Get due date in YYYY-MM-DD format

    return due < today; // Compare date strings
  };
  function ActionCell2(params) {
    return (
      <Stack direction="row" spacing={0} alignItems="center">
        {/* Three Dot Menu */}

        {/* Start Inspection Button */}
        <Tooltip title="View">
          <IconButton
            sx={{ color: "#000000" }}
            onClick={() => {
              setIsOpenScheduleInspection(true);
              setSelectedFormId(params.row);
              // setSelectedInspectionTask(params.row);
            }}
          >
            <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
          </IconButton>
        </Tooltip>

        {params.row.status === "completed" ? (
          <Tooltip title="Completed">
            <IconButton sx={{ color: "#000000" }} disabled>
              <IoIosCheckmarkCircle size={24} color="green" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Mark as Complete">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setReviewCompleteOpen(true);

                setSelectedFormId(params?.row);
              }}
            >
              <MdOutlineWatchLater size={24} color="orange" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={
            !params?.row?.has_audit_submission
              ? "Start Inspection"
              : "Edit Inspection"
          }
        >
          <IconButton
            onClick={() => {
              if (!params?.row?.has_audit_submission) {
                // navigate(
                //   `/reports/${id}/audit/${params.row.id}/inspection/create`,
                //   {
                //     state: {
                //       templateId: params.row.audit_form.id,
                //     },
                //   }
                // );
                setIsOpenCreateScheduleDrawer(true);
                setSelectedFormId(params?.row);
              } else {
                // navigate(
                //   `/reports/${id}/audit/${params.row.id}/inspection/${params.row.inspection_id}/edit`
                // );
                setIsOpenEditScheduleDrawer(true);
                setSelectedFormId(params?.row);
              }
            }}
          >
            {!params?.row?.has_audit_submission ? (
              <FaPlayCircle size={22} color="#036185" />
            ) : (
              <FaRegPlayCircle size={22} color="#036185" />
            )}
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  const getAllScheduleInspection = async () => {
    setLoading(true);

    let response = await dispatch(
      getAllServiceUnitTask({
        page_number: 1,
        // page_size: paginationModel?.pageSize,

        sort: sortKey2,

        service_unit: serviceUnitId,
        schedule_null: false,
        status:
          statusFilter?.value === "All"
            ? undefined
            : statusFilter?.value === "completed"
            ? statusFilter?.value
            : "up_coming",
        severity:
          severityFilter?.value === "All" ? undefined : severityFilter?.value,
        schedule__id:
          scheduleFilter?.value === "All" ? undefined : scheduleFilter?.value,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let tempData = [];
      if (statusFilter?.value === "pending") {
        tempData = payload?.data?.filter((item) =>
          isDueDatePast(item.due_date)
        );
      } else if (statusFilter?.value === "up_coming") {
        tempData = payload?.data?.filter(
          (item) => !isDueDatePast(item.due_date)
        );
      } else {
        tempData = payload?.data;
      }
      setScheduledInspections(tempData);

      setTotalRow(tempData?.length);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === 1) getAllScheduleInspection();
  }, [
    activeTab,
    paginationModel2,
    sortKey2,
    statusFilter,
    scheduleFilter,
    severityFilter,
  ]);

  const severityOptions = [
    { value: "All", label: "All" },
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];
  const statusOptions = [
    { value: "All", label: "All" },
    { value: "completed", label: "Completed" },

    { value: "pending", label: "Pending" },
    { value: "up_coming", label: "Upcoming" },
  ];
  const scheduleColumns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 170,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting2(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting2(e, "severity")}
        >
          Severity
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting2(e, "due_date")}
        >
          Due Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 130,
      // Flex value of 1 (10% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting2(e, "status")}
        >
          Status
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        if (row.status) {
          if (row.status !== "completed" && isDueDatePast(row.due_date)) {
            return (
              <Chip
                label={Status["PENDING"]?.label}
                style={{
                  backgroundColor: Status["PENDING"]?.color,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            );
          } else if (row.status) {
            return (
              <Chip
                label={Status[row?.status]?.label}
                style={{
                  backgroundColor: Status[row?.status]?.color,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            );
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      // Flex value of 1 (10% of the space if the total flex is 10)
      minWidth: 120,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting2(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      minWidth: 130,
      renderCell: ActionCell2,
    },
  ];

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateServiceUnitTask({
          data: { status: "completed" },
          id: selectedFormId?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Task Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        getAllScheduleInspection();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          // sx={{ justifyContent: "flex-end !important" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="TODO tabs"
            className="configure-action-tab"
          >
            <Tab label={"On-Demand Inspection"} />
            <Tab label={"Scheduled Inspection"} />
          </Tabs>

          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            {activeTab === 0 && (
              <Button
                variant="contained"
                size="large"
                className="primary-button add-btn"
                endIcon={<PlayCircleFilledWhiteIcon />}
                //   onClick={() => navigate(PAGE_URL.SERVICE_UNIT_INSPECTION_CREATE)}
                onClick={() => setOpenModal(true)}
              >
                Start Inspection
              </Button>
            )}
            {activeTab === 1 && (
              <Button
                variant="contained"
                size="large"
                className="primary-button"
                endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
                disableRipple
                onClick={() => setOpenFilterDrawer(true)}
                style={{ height: "44px" }}
              >
                Filters
              </Button>
            )}
          </Box>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Box sx={{ flexGrow: 1 }}>
            <DataGridTable
              data={
                searchTerm
                  ? formHistoryData.filter((item) =>
                      item.title
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                    )
                  : formHistoryData
              }
              columns={columns}
              setPaginationModel={setPaginationModel}
              isLoading={loading}
              paginationModel={paginationModel}
              totalRow={totalRow}
              height="calc(100vh - 350px)"
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <DataGridTable
            data={
              searchTerm
                ? scheduledInspections.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : scheduledInspections.slice(
                    paginationModel2.page * paginationModel2.pageSize,
                    (paginationModel2.page + 1) * paginationModel2.pageSize
                  )
            }
            columns={scheduleColumns}
            setPaginationModel={setPaginationModel2}
            isLoading={loading}
            paginationModel={paginationModel2}
            totalRow={totalRow}
            height="calc(100vh - 350px)"
          />
        </TabPanel>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Select Template"}
        height="300px"
        width="600px"
        open={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <DialogContent>
          <Box p={2}>
            {forms.length !== 0 && (
              <>
                {error && <div className="form-error-message">{error}</div>}
                <Selectt
                  value={selectedForm}
                  onChange={(e) => handleTypeChange(e)}
                  options={forms}
                  styles={customStyles}
                  placeholder="Select Service Unit Template"
                  defaultMenuIsOpen={true}
                />
              </>
            )}

            {!forms.length && (
              <Box
                sx={{
                  textAlign: "center",
                  padding: 3,

                  marginTop: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "#333" }}
                >
                  No Inspection Templates Found
                </Typography>
                <Typography
                  variant="label"
                  sx={{ marginTop: 1, color: "#555" }}
                >
                  Please create a new Inspection Template to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {!forms.length ? (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                disabled={loading}
                onClick={() => navigate(`/settings/formbuilder/select`)}
              >
                Create Template
              </Button>
            ) : (
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                className="primary-button"
                disabled={loading}
                onClick={() => {
                  if (!selectedForm) {
                    setError("Select Template First");
                    return;
                  }
                  // navigate(
                  //   `/service-unit/${serviceUnitId}/view/inspection/create`,
                  //   {
                  //     state: {
                  //       id: selectedForm?.value,
                  //     },
                  //   }
                  // );
                  setIsOpenCreateDrawer(true);
                  setOpenModal(false);
                }}
              >
                Start Inspection
              </Button>
            )}
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
      {isOpenCreateDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenCreateDrawer}
          setIsOpenDrawer={setIsOpenCreateDrawer}
          taskId={undefined}
          templateId={selectedForm?.value}
          fetchAllTasks={getAllForms}
          serviceUnitId={serviceUnitId}
        />
      )}
      {isOpenEditDrawer && (
        <EditFormModal
          mode={mode}
          isOpenDrawer={isOpenEditDrawer}
          setIsOpenDrawer={setIsOpenEditDrawer}
          templateId={selectedInspectionTask?.id}
          fetchAllTasks={getAllForms}
        />
      )}

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{selectedFormId?.title}</b>{" "}
              as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Severity
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={severityOptions}
            value={severityFilter}
            onChange={(e) => {
              setSeverityFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Status
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Status..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Inspection Schedule
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={scheduleOptions}
            value={scheduleFilter}
            onChange={(e) => {
              setScheduleFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Status..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
      </FilterDrawer>

      {isOpenScheduleInspection && (
        <CreateTasks
          drawerState={"view"}
          setIsOpenDrawer={setIsOpenScheduleInspection}
          isOpenDrawer={isOpenScheduleInspection}
          values={selectedFormId}
          fetchAlltasks={getAllScheduleInspection}
        />
      )}

      {isOpenCreateScheduleDrawer && (
        <CreateFormModal
          isOpenDrawer={isOpenCreateScheduleDrawer}
          setIsOpenDrawer={setIsOpenCreateScheduleDrawer}
          taskId={selectedFormId?.id}
          templateId={selectedFormId?.template_form?.id}
          fetchAllTasks={getAllScheduleInspection}
          serviceUnitId={serviceUnitId}
        />
      )}
      {isOpenEditScheduleDrawer && (
        <EditFormModal
          mode={"edit"}
          isOpenDrawer={isOpenEditScheduleDrawer}
          setIsOpenDrawer={setIsOpenEditScheduleDrawer}
          templateId={selectedFormId?.inspection_id}
          fetchAllTasks={getAllForms}
        />
      )}
    </>
  );
}
