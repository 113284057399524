import React, { useEffect, useState, useRef } from "react"; // Import useRef

import { useDispatch } from "react-redux";
import {
  createFormInstance,
  getFormById,
} from "../../../redux/slices/forms.slice";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  // Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProjectLocationAutoCompleteSuggesstionThunk } from "../../../redux/slices/tenant.slice";

import { setSnackbar } from "../../../redux/slices/common.slice";
import DeleteIcon from "@mui/icons-material/Delete";

import "../../../styles/forms.css";
import { getAllServiceUnit } from "../../../redux/slices/serviceUnit.slice";
import Select from "react-select";

import { PhotoCamera } from "@mui/icons-material";
import CustomizedDialog from "../Dialog";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),
  menu: (provided) => ({
    ...provided,

    height: "160px",
    overflowY: "auto",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "160px", // Sets a max height for the menu items list
    overflowY: "auto", // Ensures the list itself is scrollable
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

export default function CreateFormModal({
  isOpenDrawer,
  setIsOpenDrawer,
  serviceUnitId = null,
  taskId,
  templateId,
  fetchAllTasks = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [formSections, setFormSections] = useState([]);

  const [locationOption, setLocationOption] = useState([]);

  const [errors, setErrors] = useState({}); // State to manage validation errors

  const formRef = useRef(null);

  const [imageData, setImageData] = useState({});

  const [serviceUnitOptions, setServiceUnitOptions] = useState();
  const [serviceUnit, setServiceUnit] = useState();
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setImageData({});
    // setServiceUnit();
  };

  const arr = [
    {
      id: "s-1",
      name: "General Details",
      questions: [
        {
          id: "q-s-1-1",
          label: "Title",
          options: [],
          required: true,
          type: "Text Field",
          width: 6,
        },
        {
          id: "q-s-1-2",
          type: "Text Field",
          label: "Description",
          options: [],
          required: true,
          width: 12,
        },
        {
          id: "q-s-1-3",
          label: "Upload Image(s)",
          options: [],
          required: false,
          type: "Video",
          width: 12,
        },
        {
          id: "q-s-1-4",
          label: "Upload Video(s)",
          options: [],
          required: false,
          type: "Video",
          width: 12,
        },
      ],
    },
    {
      id: "s-2",
      name: "Audit Details",
      questions: [
        {
          id: "q-s-2-1",
          label: "What is the Risk Identified",
          options: [],
          required: false,
          type: "Text Field",
          width: 12,
        },
        {
          id: "q-s-2-2",
          type: "Text Field",
          label: "Description of Risk",
          options: [],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-3",
          type: "Text Field",
          label: "Impact on Project",
          options: [],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-4",
          type: "Radio Buttons",
          label: "How likely is it for the incident to happen again?",
          options: ["Low", "Medium", "High", "N/A"],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-5",
          type: "Radio Buttons",
          label: "What is the level of seriousness?",
          options: ["Low", "Medium", "High", "Extreme"],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-6",
          type: "Radio Buttons",
          label: "Change in risk since last audit",
          options: ["New Risk", "No Change", "Decreased", "Increased"],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-7",
          type: "Text Field",
          label: "Mitigation actions",
          options: [],
          required: false,
          width: 12,
        },
        {
          id: "q-s-2-8",
          type: "Text Field",
          label: "Task",
          options: [],
          required: false,
          width: 6,
        },
        {
          id: "q-s-2-9",
          type: "Number",
          label: "Cost",
          options: [],
          required: false,
          width: 6,
        },
        {
          id: "q-s-2-10",
          type: "CheckBox",
          label: "Project manager signoff",
          options: [],
          required: false,
          width: 12,
        },
      ],
    },
  ];
  const fetchAllserviceUnit = async () => {
    // setLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
        if (serviceUnitId) {
          const temp = payload?.data?.find(
            (item, idx) => item.id === +serviceUnitId
          );
          if (temp)
            setServiceUnit({
              label: temp.name,
              value: temp.id,
            });
        }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    // setLoading(false);
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, []);

  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId: templateId }));
      const { payload } = response;
      if (payload?.status) {
        setFormSections(payload?.data?.form_struct_json);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (templateId) fetchFormById();
    else setLoading(false);
  }, [templateId, isOpenDrawer]);

  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    formSections.forEach((section) => {
      section.questions.forEach((question) => {
        const value = formData[question.id];

        // Only validate required questions
        if (question.required) {
          if (question.type === "CheckBox") {
            // Checkbox can be unchecked, handle accor dingly
            if (!value) {
              newErrors[question.id] = `Required`;
            }
          } else if (question.type === "Media") {
            if (
              !imageData[question.label] || // Check if it's null, undefined, or an empty object
              (Array.isArray(imageData[question.label]) &&
                imageData[question.label].length === 0) // Check if it's an empty array
            ) {
              newErrors[question.id] = `Required`; // Add an error if validation fails
            }
          } else if (question.type === "Video") {
            if (
              !imageData[question.label] || // Check if it's null, undefined, or an empty object
              (Array.isArray(imageData[question.label]) &&
                imageData[question.label].length === 0) // Check if it's an empty array
            ) {
              newErrors[question.id] = `Required`; // Add an error if validation fails
            }
          } else if (!value) {
            newErrors[question.id] = `Required`;
          }
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };
  function removeUntilFirstComma(str) {
    const commaIndex = str.indexOf(",");
    if (commaIndex !== -1) {
      return str.substring(commaIndex + 1); // +1 to skip the comma itself
    }
    return str; // return original string if no comma is found
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formElement = formRef.current;
    let data = new FormData(formElement);
    const formData = Object.fromEntries(data);

    if (!validateForm(formData)) return; // Prevent submission if validation fails
    setLoading(true);
    const finalData = {
      form: +templateId,
      task: +taskId,
      service_unit: serviceUnit?.value,
      submission_json: formSections.map((section) => {
        return {
          section: section.id,
          section_name: section.name,
          questions: section.questions.map((question) => {
            let isSelected = null;
            let groupValue = null;
            let images = null;
            let videos = null;
            let answer = "";

            if (question.type === "CheckBox") {
              isSelected = formData[question.id] === "true";
            } else if (question.type === "Radio Buttons") {
              groupValue = formData[`${question.id}`] || null;
            } else if (question.type === "Media") {
              images =
                imageData[question.label]?.map((item) => {
                  return {
                    base: removeUntilFirstComma(item.base64) || "", // Fallback if undefined
                    extension: item.extension || "", // Fallback if undefined
                  };
                }) || [];
            } else if (question.type === "Video") {
              videos =
                imageData[question.label]?.map((item) => {
                  return {
                    base: removeUntilFirstComma(item.base64) || "", // Fallback if undefined
                    extension: item.extension || "", // Fallback if undefined
                  };
                }) || [];
            } else {
              answer = formData[question.id] || null;
            }

            return {
              question_id: question.id,
              answer: answer,
              answer_type: question.type,
              group_value: groupValue,
              is_selected: isSelected,
              required: question.required,
              images: images,
              videos: videos,
              label: question.label,
              width: question.width,
              options: question.options,
            };
          }),
        };
      }),
    };

    try {
      let response = await dispatch(
        createFormInstance({
          formData: finalData,
        })
      );
      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Inspection Form Submitted Succesfully.",
          })
        );
        handleCloseDrawer();
        fetchAllTasks();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <>
      <CustomizedDialog
        className="modal-scroll popup"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={"Start Inspection"}
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                margin: "auto",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <form ref={formRef} onSubmit={handleSubmit}>
                {formSections &&
                  formSections.map((section) => (
                    <Accordion key={section.id} defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        className="accordion-summary-background accordion-summary-root"
                      >
                        <Typography className="accordion-summary-typography">
                          {section.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                        <Grid container spacing={3}>
                          {section.questions.map((question) => (
                            <Grid item xs={question.width} key={question.id}>
                              <Typography variant="body1" component="label">
                                {question?.label}
                                {question.required && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </Typography>

                              {question.type === "Text Field" && (
                                <>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Your answer here"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors };
                                        delete newErrors[name];
                                        return newErrors;
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "CheckBox" && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={question.id}
                                        value={imageData[question.label]}
                                        onChange={(e) => {
                                          const { name, checked } = e.target; // Get the name and value of the input field
                                          // Update the errors state to remove the error for the current question
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[name]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });

                                          setImageData((prevData) => {
                                            const newData = { ...prevData };
                                            newData[question.label] = checked;
                                            return newData;
                                          });
                                        }}
                                      />
                                    }
                                    sx={{ fontWeight: 600 }}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Number" && (
                                <>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter a number"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Date & Time" && (
                                <>
                                  <TextField
                                    type="datetime-local"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Time" && (
                                <>
                                  <TextField
                                    type="time"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    value={imageData[question.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Date" && (
                                <>
                                  <TextField
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    required={question.required}
                                    name={question.id}
                                    value={imageData[question?.label] || ""}
                                    onChange={(e) => {
                                      const { name, value } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value;
                                        return newData;
                                      });
                                    }}
                                    error={!!errors[question.id]}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                              {question.type === "Location" && (
                                <>
                                  <Autocomplete
                                    className="project-location-autocomplete"
                                    freeSolo
                                    onInputChange={(event, values) => {
                                      handleAutocompleteChange(event, values);
                                    }}
                                    value={imageData[question.label] || ""}
                                    onChange={(e, value) => {
                                      const { name } = e.target; // Get the name and value of the input field
                                      // Update the errors state to remove the error for the current question
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[name]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = value.label;
                                        return newData;
                                      });
                                    }}
                                    options={locationOption}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        id="outlined-basic"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name={question.id}
                                        placeholder="Please, Enter location"
                                        error={!!errors[question.id]}
                                      />
                                    )}
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}

                              {question.type === "Media" && (
                                <>
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100% ",
                                    }}
                                  >
                                    Upload Images
                                    <input
                                      type="file"
                                      accept="image/*"
                                      hidden
                                      name={question.id}
                                      multiple // Allow multiple files
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (Array.from(files)?.length > 0) {
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[question.id]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });
                                        }
                                        const imageList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base64: reader.result,
                                                  extension:
                                                    file.type.split("/")[1], // Get the file extension
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            });
                                          }
                                        );

                                        Promise.all(imageList).then(
                                          (images) => {
                                            setImageData((prevData) => {
                                              const newData = { ...prevData };
                                              newData[question.label] = images;
                                              return newData;
                                            });
                                          }
                                        );
                                      }}
                                    />
                                  </Button>
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                  {imageData[question.label] &&
                                    imageData[question.label].length > 0 && (
                                      <Box
                                        sx={{
                                          mt: 2,
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 2,
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          {imageData[question.label].map(
                                            (image, index) => (
                                              <Grid item xs={2} key={index}>
                                                <Box
                                                  key={index}
                                                  sx={{
                                                    position: "relative",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  <img
                                                    src={image.base64}
                                                    alt={`Preview ${index + 1}`}
                                                    style={{
                                                      width: "100%", // Set the desired width
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "8px",
                                                    }}
                                                  />
                                                  {/* Optional: Add a delete button for individual images */}
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                      const updatedImages =
                                                        imageData[
                                                          question.label
                                                        ].filter(
                                                          (_, i) => i !== index
                                                        );
                                                      setImageData(
                                                        (prevData) => {
                                                          const newData = {
                                                            ...prevData,
                                                          };
                                                          newData[
                                                            question.label
                                                          ] = updatedImages; // Update images after deletion
                                                          return newData;
                                                        }
                                                      );
                                                    }}
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      backgroundColor:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      color: "white",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.8)",
                                                      },
                                                    }}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}
                                </>
                              )}
                              {question.type === "Video" && (
                                <>
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    sx={{
                                      mt: 1,
                                      mb: 2,
                                      color: "#036185",
                                      width: "100%",
                                    }}
                                  >
                                    Upload Videos
                                    <input
                                      type="file"
                                      accept="video/*"
                                      hidden
                                      name={question.id}
                                      multiple // Allow multiple videos
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (Array.from(files)?.length > 0) {
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                            delete newErrors[question.id]; // Remove the error for the specific question using its name
                                            return newErrors; // Return the updated errors object
                                          });
                                        }

                                        const videoList = Array.from(files).map(
                                          (file) => {
                                            const reader = new FileReader();
                                            return new Promise((resolve) => {
                                              reader.onloadend = () => {
                                                resolve({
                                                  base64: reader.result,
                                                  extension:
                                                    file.type.split("/")[1], // Get the file extension
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            });
                                          }
                                        );

                                        Promise.all(videoList).then(
                                          (videos) => {
                                            setImageData((prevData) => {
                                              const newData = { ...prevData };
                                              newData[question.label] = videos;
                                              return newData;
                                            });
                                          }
                                        );
                                      }}
                                    />
                                  </Button>
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                  {imageData[question.label] &&
                                    imageData[question.label].length > 0 && (
                                      <Box
                                        sx={{
                                          mt: 2,
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 2,
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          {imageData[question.label].map(
                                            (video, index) => (
                                              <Grid item xs={4} key={index}>
                                                <Box
                                                  key={index}
                                                  sx={{
                                                    position: "relative",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  <video
                                                    src={video.base64}
                                                    controls
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "8px",
                                                    }}
                                                  />
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                      const updatedVideos =
                                                        imageData[
                                                          question.label
                                                        ].filter(
                                                          (_, i) => i !== index
                                                        );
                                                      setImageData(
                                                        (prevData) => {
                                                          const newData = {
                                                            ...prevData,
                                                          };
                                                          newData[
                                                            question.label
                                                          ] = updatedVideos; // Update videos after deletion
                                                          return newData;
                                                        }
                                                      );
                                                    }}
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      backgroundColor:
                                                        "rgba(0, 0, 0, 0.5)",
                                                      color: "white",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.8)",
                                                      },
                                                    }}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    )}
                                </>
                              )}

                              {question.type === "Radio Buttons" && (
                                <>
                                  <FormControl
                                    component="fieldset"
                                    fullWidth
                                    required={question.required}
                                  >
                                    <RadioGroup
                                      name={`${question.id}`}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                      value={
                                        imageData[question.label] ||
                                        question.options[0]
                                      }
                                      defaultValue={question.options[0]}
                                      onChange={(e) => {
                                        setImageData((prevData) => {
                                          const newData = { ...prevData };
                                          newData[question.label] =
                                            e.target.value;
                                          return newData;
                                        });
                                      }}
                                    >
                                      {question.options.map((option, index) => (
                                        <FormControlLabel
                                          key={index}
                                          value={option}
                                          control={<Radio />}
                                          label={option}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              )}

                              {question.type === "Service Unit" && (
                                <>
                                  <Select
                                    options={serviceUnitOptions}
                                    value={serviceUnit}
                                    onChange={(e) => {
                                      setServiceUnit(e);
                                      setErrors((prevErrors) => {
                                        const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                        delete newErrors[`${question.id}`]; // Remove the error for the specific question using its name
                                        return newErrors; // Return the updated errors object
                                      });
                                      setImageData((prevData) => {
                                        const newData = { ...prevData };
                                        newData[question.label] = e;
                                        return newData;
                                      });
                                    }}
                                    name={`${question.id}`}
                                    styles={customStyles}
                                    placeholder="Select Type"
                                  />
                                  {errors[question.id] && (
                                    <div className="form-error-message">
                                      {errors[question.id]}
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </form>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box className="modal-footers">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                className="primary-button"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Save
              </Button>

              <Button
                variant="outlined"
                className="secondary-button"
                disabled={loading}
                onClick={handleCloseDrawer}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}
