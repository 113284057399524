import {
  Select,
  MenuItem,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import DateRangePicker from "./dateRangePicker";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/common.slice";
import {
  getAllUsersThunk,
  getAuditCountThunk,
  getDashboardReportCountThunk,
  getDashboardSafetyBuddyCountThunk,
  getProjectSelectionThunk,
  getServiceTaskCountThunk,
  getTaskCountThunk,
} from "../../redux/slices/tenant.slice";
import { getDashboardCountThunk } from "../../redux/slices/tenant.slice";
import { useNavigate } from "react-router-dom";
import { CoPresentOutlined } from "@mui/icons-material";
import ReactECharts from "echarts-for-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import { getAllServiceUnit } from "../../redux/slices/serviceUnit.slice";
import Modal from "./insightDatamodal";
Exporting(Highcharts);
OfflineExporting(Highcharts);
ExportData(Highcharts);
const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
    width: 160,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};
const Insights = () => {
  let currentDate = new Date();
  let firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  firstDayOfMonth = moment(firstDayOfMonth).format("MM-DD-YYYY");
  currentDate = moment(currentDate).format("MM-DD-YYYY");

  // Report related date range
  const [dateRange, setDateRange] = useState([firstDayOfMonth, currentDate]);
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [projectFilter, setProjectFilter] = useState("all");

  // report inside date range
  const [reportInsideDateRange, setReportInsideDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [reportInsideUserFilter, setReportInsideUserFilter] = useState("all");
  const [reportInsideProjectFilter, setReportInsideProjectFilter] =
    useState("all");
  const [reportInsideData, setReportInsideData] = useState([]);
  const [reportXLabel, setReportXLabel] = useState([]);
  const [reportSafetyBuddyCount, setReportSafetyBudddyCount] = useState([]);
  const [reportCount, setReportCount] = useState([]);

  const [userData, setUserData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [serviceUnits, setServiceUnits] = useState([]);
  const [dashboardCount, setDashboardCount] = useState({});

  const [serviceUnitData, setServiceUnitData] = useState([]);
  const [serviceUserTypeFilter, setServiceUserTypeFilter] = useState("all");
  const [serviceProjectFilter, setServiceProjectFilter] = useState("all");
  const [auditData, setAuditData] = useState([]);
  const [auditUserTypeFilter, setAuditUserTypeFilter] = useState("all");
  const [auditProjectFilter, setAuditProjectFilter] = useState("all");

  const [auditDateRange, setAuditDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [serviceDateRange, setServiceDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const showTablePopup = (tableHTML) => {
    setModalContent(tableHTML);
    setIsModalOpen(true);
  };
  const navigate = useNavigate();

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleReportInsideDateRange = (newDateRange) => {
    setReportInsideDateRange(newDateRange);
  };
  const handleServiceDateRange = (newDateRange) => {
    setServiceDateRange(newDateRange);
  };
  const handleAuditDateRange = (newDateRange) => {
    setAuditDateRange(newDateRange);
  };

  const highlightScope = {
    highlighted: "series",
    faded: "global",
  };

  const dispatch = useDispatch();

  // Fetch all user information
  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All User", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.first_name} ${element?.last_name}`,
          value: element?.id,
        });
        setUserData(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  const fetchAllServiceUnits = async () => {
    let response = await dispatch(
      getAllServiceUnit({
        page_number: 1,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All Service Unit", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.name}`,
          value: element?.id,
        });
        setServiceUnits(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  // Fetch all porojet selection related information
  const fetchAllProject = async () => {
    let response = await dispatch(getProjectSelectionThunk());

    const { payload } = response;

    if (payload?.status) {
      let temp = [{ label: "All Project", value: "all" }];
      payload?.data?.project?.map((element) => {
        temp.push({
          label: element?.name,
          value: element?.id,
        });
      });
      setProjectData(temp);
    }
  };

  const fetchDashboardCount = async () => {
    let response = await dispatch(getDashboardCountThunk({}));
    const { payload } = response;

    if (payload?.status) {
      setDashboardCount(payload?.data);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };
  console.log(serviceUnits);
  const fetchDashboardReportCount = async () => {
    let send_payload = {};
    if (reportInsideDateRange?.length > 0) {
      send_payload["start"] =
        reportInsideDateRange[0] == ""
          ? undefined
          : moment(reportInsideDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        reportInsideDateRange[1] == ""
          ? undefined
          : moment(reportInsideDateRange[1]).format("YYYY-MM-DD");
    }

    if (reportInsideUserFilter !== "all") {
      send_payload["user_id"] = reportInsideUserFilter;
    }
    if (reportInsideProjectFilter !== "all") {
      send_payload["project_id"] = reportInsideProjectFilter;
    }

    let response = await dispatch(getDashboardReportCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      let tempData = [
        {
          id: 0,
          value: payload?.data?.approved_report,
          label: "Completed Report",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.pending_report,
          label: "Pending Report",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.in_review || 0,
          label: "In Review Report",
          color: "orange",
        },
      ];
      console.log(tempData);
      setReportInsideData(tempData);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const fetchServiceUnitData = async () => {
    let send_payload = {};
    if (serviceDateRange?.length > 0) {
      send_payload["start"] =
        serviceDateRange[0] == ""
          ? undefined
          : moment(serviceDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        serviceDateRange[1] == ""
          ? undefined
          : moment(serviceDateRange[1]).format("YYYY-MM-DD");
    }

    if (serviceUserTypeFilter !== "all") {
      send_payload["users"] = serviceUserTypeFilter;
    }
    if (serviceProjectFilter !== "all") {
      send_payload["service_unit_id"] = serviceProjectFilter;
    }

    let response = await dispatch(getServiceTaskCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      let tempData = [
        {
          id: 0,
          value: payload?.data?.inspection?.completed,
          label: "Completed Task",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.inspection?.pending,
          label: "Pending Task",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.inspection?.up_coming,
          label: "Upcoming Task",
          color: "orange",
        },
      ];
      setServiceUnitData(tempData);
    }
  };
  const fetchAuditData = async () => {
    let send_payload = {};
    if (auditDateRange?.length > 0) {
      send_payload["start"] =
        auditDateRange[0] == ""
          ? undefined
          : moment(auditDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        auditDateRange[1] == ""
          ? undefined
          : moment(auditDateRange[1]).format("YYYY-MM-DD");
    }

    if (auditUserTypeFilter !== "all") {
      send_payload["users"] = auditUserTypeFilter;
    }
    if (auditProjectFilter !== "all") {
      send_payload["project_id"] = auditProjectFilter;
    }

    let response = await dispatch(getAuditCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      let tempData = [
        {
          id: 0,
          value: payload?.data?.audit?.completed,
          label: "Completed Task",
          color: "green",
        },
        {
          id: 1,
          value: payload?.data?.audit?.pending,
          label: "Pending Task",
          color: "red",
        },
        {
          id: 2,
          value: payload?.data?.audit?.up_coming,
          label: "Upcoming Task",
          color: "orange",
        },
      ];
      setAuditData(tempData);
    }
  };

  const fetchSafetyBuddyReportCount = async () => {
    let send_payload = {};
    if (dateRange?.length > 0) {
      send_payload["start"] =
        dateRange[0] == ""
          ? undefined
          : moment(dateRange[0]).format("YYYY-MM-DD");
      send_payload["end"] =
        dateRange[1] == ""
          ? undefined
          : moment(dateRange[1]).format("YYYY-MM-DD");
    }

    if (userTypeFilter !== "all") {
      send_payload["user_id"] = userTypeFilter;
    }

    if (projectFilter !== "all") {
      send_payload["project_id"] = projectFilter;
    }

    let response = await dispatch(
      getDashboardSafetyBuddyCountThunk(send_payload)
    );
    const { payload } = response;

    if (payload?.status) {
      let dates = [];
      let currentDate = new Date(dateRange[0]);
      const finalDate = new Date(dateRange[1]);

      if (currentDate > finalDate) {
        throw new Error("startDate must be before endDate");
      }

      while (currentDate <= finalDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let x_label = [];
      let safety_count = [];
      let report_count = [];

      dates?.map((element) => {
        let temp_date = moment(element).format("YYYY-MM-DD");
        let safety_buddy_push = false;
        let report_push = false;

        payload?.data?.safety_buddy?.map((dateCount) => {
          if (temp_date == dateCount?.date) {
            x_label.push(temp_date);
            safety_count.push(dateCount?.count);
            safety_buddy_push = true;
          }
        });

        payload?.data?.report?.map((dateCount) => {
          if (temp_date == dateCount?.date) {
            if (!x_label.includes(temp_date)) {
              x_label.push(temp_date);
            }
            report_count.push(dateCount?.count);
            report_push = true;
          }
        });

        if (safety_buddy_push == false && report_push == true) {
          safety_count.push(0);
        }

        if (report_push == false && safety_buddy_push == true) {
          report_count.push(0);
        }
      });

      setReportXLabel(x_label);
      setReportSafetyBudddyCount(safety_count);
      setReportCount(report_count);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllUser();
    fetchDashboardCount();
    fetchAllProject();
    fetchAllServiceUnits();
  }, []);

  useEffect(() => {
    fetchDashboardReportCount();
  }, [
    reportInsideUserFilter,
    reportInsideDateRange,
    reportInsideProjectFilter,
  ]);

  useEffect(() => {
    fetchSafetyBuddyReportCount();
  }, [userTypeFilter, dateRange, projectFilter]);

  useEffect(() => {
    fetchServiceUnitData();
  }, [serviceDateRange, serviceUserTypeFilter, serviceProjectFilter]);
  useEffect(() => {
    fetchAuditData();
  }, [auditDateRange, auditUserTypeFilter, auditProjectFilter]);

  const barChartOptions = (data, title) => {
    return {
      chart: {
        type: "bar", // Bar chart (Horizontal)
      },
      title: {
        text: title,
      },

      xAxis: {
        categories: reportXLabel,
        title: {
          text: "Category",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
          // align: "high",
        },
      },
      series: [
        {
          name: "Safety Buddy",
          data: data?.safetyBuddy,
          color: "#036185",
        },
        {
          name: "Report",
          data: data?.report,
        },
      ],
      tooltip: {
        shared: true,
        valueSuffix: " counts",
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
              "viewData",
              // {
              //   text: "View Data Table",
              //   onclick: function () {
              //     const tableHTML = this.getTable();
              //     showTablePopup(tableHTML); // Custom function to handle popup
              //   },
              // },
            ],
          },
        },
      },
    };
  };

  // Pie Chart Options
  const pieChartOptions = (data, title) => {
    return {
      chart: {
        type: "pie",
      },
      title: {
        text: title,
      },

      series: [
        {
          name: "Reports",
          colorByPoint: true,
          data: data.map((item) => ({
            name: item.label,
            y: item.value,
            color: item.color || "#7cb5ec",
          })),
        },
      ],
      tooltip: {
        pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f}%",
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
              "viewData",
            ],
          },
        },
      },
    };
  };

  // Line Chart Options

  // Function to switch chart types

  return (
    <div style={{ height: "calc(100vh - 170px)", overflowY: "auto" }}>
      <Grid container spacing={3}>
        {/* Total Users Card */}
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Users
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.user}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Reports Card */}
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Reports
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.report}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Safety Buddy Requests Card */}
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Safety Buddy Requests
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.safety_check}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Bar Chart for Safety Buddy / Report */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={dateRange}
                    onDateRangeChange={setDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={userTypeFilter}
                    onChange={(e) => setUserTypeFilter(e.target.value)}
                    name="status"
                    className="dashboard-selection"
                    size="small"
                    displayEmpty
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={projectFilter}
                    onChange={(e) => setProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={barChartOptions(
                      {
                        safetyBuddy: reportSafetyBuddyCount,
                        report: reportCount,
                      },
                      "Safety Buddy / Report"
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Pie Chart for Reports */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={reportInsideDateRange}
                    onDateRangeChange={handleReportInsideDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={reportInsideUserFilter}
                    onChange={(e) => setReportInsideUserFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={reportInsideProjectFilter}
                    onChange={(e) =>
                      setReportInsideProjectFilter(e.target.value)
                    }
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {reportInsideData?.reduce(
                      (total, item) => total + item.value,
                      0
                    ) > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={pieChartOptions(reportInsideData, "Reports")}
                      />
                    ) : (
                      <Box height={200} className="center">
                        No Reports Found
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Pie Chart for Service Unit Tasks */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={serviceDateRange}
                    onDateRangeChange={handleServiceDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={serviceUserTypeFilter}
                    onChange={(e) => setServiceUserTypeFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={serviceProjectFilter}
                    onChange={(e) => setServiceProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {serviceUnits.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {serviceUnitData?.reduce(
                      (total, item) => total + item.value,
                      0
                    ) > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={pieChartOptions(
                          serviceUnitData,
                          "Service Unit Tasks"
                        )}
                      />
                    ) : (
                      <Box height={200} className="center">
                        No Service Unit Tasks Found
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Incident Audits Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ minHeight: 400 }}>
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangePicker
                    dateRange={auditDateRange}
                    onDateRangeChange={handleAuditDateRange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={auditUserTypeFilter}
                    onChange={(e) => setAuditUserTypeFilter(e.target.value)}
                    className="dashboard-selection"
                    size="small"
                  >
                    {userData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={auditProjectFilter}
                    onChange={(e) => setAuditProjectFilter(e.target.value)}
                    displayEmpty
                    name="status"
                    className="dashboard-selection"
                    size="small"
                  >
                    {projectData.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {auditData?.reduce((total, item) => total + item.value, 0) >
                    0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={pieChartOptions(auditData, "Incident Audits")}
                      />
                    ) : (
                      <Box height={200} className="center">
                        No Audits Found
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
      />
    </div>
  );
};

export default Insights;
