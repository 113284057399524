import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Drawer,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import { RiCloseLine } from "react-icons/ri";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../redux/slices/dashboard.slice";
import { setSnackbar } from "../../redux/slices/common.slice";
import PendingIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import {
  getAllUsersThunk,
  getTenantReportById,
} from "../../redux/slices/tenant.slice";
import CreateReport from "../common/createReport";
export default function NotificationDrawer({
  openDrawer,
  setOpenDrawer,
  notifications,
  fetchNotifications,
}) {
  const [loading, setLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [value, setValue] = React.useState("all");
  const [reportLayout, setReportLayout] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [token, setToken] = useState();
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let filteredNotifications = notifications;
  if (value === "unread") {
    filteredNotifications = notifications.filter((item) => !item.is_read);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      const tempData = payload.data?.map((item) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));
      setUserData(tempData);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  const handleUpdateIsReviewed = async (event, id, key) => {
    event.stopPropagation();
    setReviewLoading(true);
    try {
      let response = await dispatch(
        updateNotification({
          data: { is_reviewed: true },
          notificationId: id,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        await fetchNotifications();
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Notification is Marked as Reviewed!",
          })
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setReviewLoading(false);
  };
  const handleUpdateIsRead = async (event, notification, key) => {
    setLoading(true);
    try {
      let response = await dispatch(
        updateNotification({
          data: { [key]: true },
          notificationId: notification?.id,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        await fetchNotifications();

        fetchReportById(notification?.report?.id);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const fetchReportById = async (id) => {
    setLoading(true);

    let response = await dispatch(getTenantReportById({ id: id }));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setToken(payload?.token);
      setReportLayout(true);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Drawer
        className="side-drawer-2 report-side-drawer"
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper-2">
          <Box className="close-btn">
            <RiCloseLine onClick={handleCloseDrawer} />
          </Box>
          <Box className="sidebar-tab">
            <Tab
              className="tab-label-btn contact-label"
              label={"Notifications"}
              disabled
            ></Tab>
          </Box>
        </Box>

        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .Mui-selected": {
              color: "var(--color-theme-secondary)",
            }, // Selected tab text color
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--color-theme-secondary)",
              height: "3px",
            }, // Indicator color }}
          }}
        >
          <Tab value="all" label="All" />
          <Tab value="unread" label="Unread" />
        </Tabs>

        <Box sx={{ p: 2, overflowY: "auto" }}>
          {filteredNotifications?.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                sx={{ fontSize: "18px" }}
              >
                No Notifications found
              </Typography>
            </Box>
          ) : (
            filteredNotifications?.map((notification) => (
              <Card
                key={notification.id}
                sx={{
                  mb: 2,
                  cursor: "pointer",

                  transition: "background-color 0.3s ease, opacity 0.3s ease",
                }}
                onClick={(e) => handleUpdateIsRead(e, notification, "is_read")}
              >
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    {/* Notification Image */}
                    <Grid item>
                      <Avatar
                        src={notification.report.image[0]}
                        alt={notification.title}
                        sx={{ width: 72, height: 72, borderRadius: "10px" }}
                      />
                    </Grid>
                    {/* Notification Details */}
                    <Grid item xs>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          {notification.title}
                        </Typography>

                        {/* Green dot for unread */}
                        {!notification.is_read && (
                          <Box
                            sx={{
                              width: 10,
                              height: 10,
                              bgcolor: "#37b24d",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </Box>
                      <Typography variant="label" color="textSecondary">
                        {notification.description}
                      </Typography>
                    </Grid>
                    {/* Menu Button (Three Dots) */}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",

                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption" color="textSecondary">
                        {moment(notification.created_at, "HH:mm").format(
                          "hh:mm A"
                        )}
                      </Typography>

                      {notification?.is_reviewed ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                          }}
                        >
                          <Tooltip title="Reviewed" arrow>
                            <IconButton
                              sx={{ marginRight: "4px", color: "success.main" }}
                            >
                              <CheckCircleIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Typography
                            variant="label"
                            sx={{
                              color: notification?.is_reviewed
                                ? "success.main"
                                : "text.primary",
                              fontWeight: 500,
                            }}
                          >
                            Reviewed
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px 10px",

                            transition: "all 0.3s ease", // Smooth transition for the hover effects
                            "&:hover": {
                              backgroundColor: "#fff4e6", // Change background on hover
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover
                              transform: "translateY(-1px)", // Move the button upwards slightly
                            },
                          }}
                          onClick={(e) =>
                            handleUpdateIsReviewed(
                              e,
                              notification?.id,
                              "is_reviewed"
                            )
                          }
                        >
                          {reviewLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <>
                              <Tooltip title="Mark as Reviewed" arrow>
                                <IconButton
                                  sx={{
                                    marginRight: "4px",
                                    color: "warning.main",
                                  }}
                                >
                                  <PendingIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Typography
                                variant="label"
                                sx={{
                                  color: notification?.is_reviewed
                                    ? "success.main"
                                    : "text.primary",
                                  fontWeight: 500,
                                }}
                              >
                                Mark As Review
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                    </Grid>
                    {/* Time in the bottom-right corner */}
                  </Grid>
                </CardContent>
                <Divider />
              </Card>
            ))
          )}
        </Box>
      </Drawer>
      {reportLayout && reportData && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          drawerState={"edit"}
          values={reportData}
          fetchAllReport={() => {}}
          users={userData}
          imageToken={token}
          // setReportId={setReportId}
        />
      )}
    </>
  );
}
