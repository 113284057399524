import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Box,
  Collapse,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  DialogContent,
  ButtonGroup,
  FormControl,
  Typography,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RiAddCircleLine, RiDeleteBinLine } from "react-icons/ri";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Cancel";
import TextFieldIcon from "@mui/icons-material/TextFields";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import NumericIcon from "@mui/icons-material/AttachMoney"; // Replace with a suitable numeric icon
import DateTimeIcon from "@mui/icons-material/CalendarToday"; // Replace with a suitable date-time icon
import CustomizedDialog from "../../Dialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FormRenderer from "./formRenderer";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useDispatch } from "react-redux";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import {
  createForm,
  getFormById,
  getFormByType_SubType,
  getFormSubTypesByid,
  updateForm,
} from "../../../../redux/slices/forms.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import Loader from "../../Loader";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { TRUE } from "sass";
import { FaVideo } from "react-icons/fa";

const customStyles = {
  width: "400px !important",
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
    height: " 20px",
    minHeight: " 10px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const subTypesMapping = {
  aadhar: "Employee Induction - Aadhar Card",
  pancard: "Employee Induction - PAN Card",
  passport: "Employee Induction - Passport",
  indian_dl: "Employee Induction - Indian Driving License",
  american_dl: "Employee Induction - US Driving License",
  work_permit: "Work Permit",
  pre_trip_inspection: "Pre Trip Inspection",
  post_incident_inspection: "Post Incident Inspection",
  post_incident_audit: "Post Incident Audit",
};

//const initialData = [];
export default function DynamicFormBuilder({ mode }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { title, description } = location.state || {}; // Retrieve the `id` from `state`

  const [sections, setSections] = useState([
    {
      id: "s-1",
      name: "Section 1",
      questions: [
        {
          id: "q-s-1-1",
          label: "Title",
          options: [],
          required: true,
          type: "Text Field",
          width: 6,
          disabled: true,
        },
      ],
    },
  ]);
  const params = new URLSearchParams(location.search);

  let { formType, formId } = useParams();
  if (mode === "create") {
    formId = params.get("formId");
  }
  const [formTitle, setFormTitle] = useState(title || "");
  const [formDescription, setFormDescription] = useState(description || "");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dummySubType, setDummySubType] = useState("Form Type");
  const [formTemplate, setFormTemplate] = useState(formId);

  const [formSubTypes, setFormSubTypes] = useState([]);

  const [formSubType, setFormSubType] = useState("");

  const [expandedQuestionId, setExpandedQuestionId] = useState(
    sections.reduce((acc, section) => {
      const expandedQuestions = section.questions.map((q) => q.id);
      return [...acc, ...expandedQuestions];
    }, [])
  );
  const navigate = useNavigate();
  console.log(loading);
  const fetchFormByType_SubType = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getFormByType_SubType({ formType, formSubType })
      );
      const { payload } = response;
      if (payload?.status) {
        setSections(payload?.data?.form_struct_json);

        setFormTemplate(payload?.data?.reference_template?.id);
        setExpandedQuestionId(
          payload?.data?.form_struct_json.reduce((acc, section) => {
            const expandedQuestions = section.questions.map((q) => q.id);
            return [...acc, ...expandedQuestions]; // Keep all questions open
          }, [])
        );

        if (!title && !description && !payload?.data?.is_template) {
          setFormTitle(payload?.data?.title);
          setFormDescription(payload?.data?.description);
        }
        if (mode === "create") setLoading(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };
  console.log(dummySubType);
  const fetchFormById = async (id) => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId }));
      const { payload } = response;
      if (payload?.status) {
        setSections(payload?.data?.form_struct_json);

        setDummySubType(payload?.data?.sub_type);
        setFormTemplate(payload?.data?.reference_template?.id);
        setExpandedQuestionId(
          payload?.data?.form_struct_json.reduce((acc, section) => {
            const expandedQuestions = section.questions.map((q) => q.id);
            return [...acc, ...expandedQuestions]; // Keep all questions open
          }, [])
        );

        if (!title && !description && !payload?.data?.is_template) {
          setFormTitle(payload?.data?.title);
          setFormDescription(payload?.data?.description);
        }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formType && formSubType && formType !== "custom")
      fetchFormByType_SubType();
  }, [mode, formType, formSubType]);

  useEffect(() => {
    if (formId) fetchFormById();
  }, [formId, formSubType]);

  const fetchFormSubTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getFormSubTypesByid({ formType: formType })
      );
      const { payload } = response;
      if (payload?.status) {
        setFormSubTypes(payload?.data);
        if (mode === "edit") {
          setFormSubType(undefined);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formType && formType !== "custom") {
      fetchFormSubTypes();
    }
  }, [formType]);

  const questionTypes = [
    "Text Field",
    "CheckBox",
    "Number",
    "Date & Time",
    "Date",
    "Time",
    "Location",
    "Media",
    "Video",
    "Radio Buttons",
    "Service Unit",
  ];
  const questionTypeIcons = {
    "Text Field": <TextFieldIcon />,
    CheckBox: <CheckboxIcon />,
    Number: <NumericIcon />,
    "Date & Time": <DateTimeIcon />,
    Date: <DateRangeIcon />,
    Time: <AccessTimeIcon />,
    Location: <LocationOnIcon />,
    Media: <PermMediaIcon />,
    Video: <VideoCameraFrontIcon />,
    "Radio Buttons": <RadioButtonCheckedIcon />,
    "Service Unit": <PrecisionManufacturingIcon />,
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        id: `s-${sections.length + 1}`,
        name: `Section ${sections.length + 1}`,
        questions: [
          {
            id: `q-s-${sections.length + 1}-1`,
            label: "",
            options: [],
            required: false,
            type: "Text Field",
            width: 12,
          },
        ],
      },
    ]);
    setExpandedQuestionId(
      (prevState) => [...prevState, `q-s-${sections.length + 1}-1`] // Expand
    );
  };

  const addQuestion = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        setExpandedQuestionId(
          (prevState) => [
            ...prevState,
            `q-${sectionId}-${section.questions.length + 1}`,
          ] // Expand
        );
        return {
          ...section,
          questions: [
            ...section.questions,
            {
              id: `q-${sectionId}-${section.questions.length + 1}`,
              type: "Text Field",
              label: "",
              options: [],
              required: false, // Add required field to the question
              width: 12,
            },
          ],
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const removeQuestion = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          questions: section.questions.filter((q) => q.id !== questionId),
        };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const removeSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  const toggleQuestionDropdown = (questionId) => {
    setExpandedQuestionId(
      (prevState) =>
        prevState.includes(questionId)
          ? prevState.filter((id) => id !== questionId) // Collapse
          : [...prevState, questionId] // Expand
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "sections" &&
      destination.droppableId === "sections"
    ) {
      const reorderedSections = Array.from(sections);
      const [removed] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, removed);
      setSections(reorderedSections);
    } else if (source.droppableId === destination.droppableId) {
      const section = sections.find((sec) => sec.id === source.droppableId);
      const reorderedQuestions = Array.from(section.questions);
      const [removed] = reorderedQuestions.splice(source.index, 1);
      reorderedQuestions.splice(destination.index, 0, removed);

      const updatedSections = sections.map((sec) => {
        if (sec.id === section.id) {
          return { ...sec, questions: reorderedQuestions };
        }
        return sec;
      });

      setSections(updatedSections);
    }
  };

  const handleRequiredChange = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedQuestions = section.questions.map((q) => {
          if (q.id === questionId) {
            return { ...q, required: !q.required }; // Toggle required status
          }
          return q;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    setSections(updatedSections);
  };
  const handleWidthChange = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedQuestions = section.questions.map((q) => {
          if (q.id === questionId) {
            return { ...q, width: q.width === 12 ? 6 : 12 }; // Toggle required status
          }
          return q;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    setSections(updatedSections);
  };

  const onSubmit = () => {
    const updatedSections = sections.map((section) => {
      const updatedQuestions = section.questions.map((question) => {
        // Check if the question has options (not all question types do)
        if (question.options && question.options.length > 0) {
          return {
            ...question,
            // Remove duplicate options
            options: removeDuplicates(question.options),
          };
        }
        return question; // If no options, return the question unchanged
      });

      return {
        ...section,
        questions: updatedQuestions,
      };
    });
    setSections(updatedSections);
    setOpenPreview(true);
  };

  const removeDuplicates = (options) => {
    const seen = new Map();
    return options.filter((option) => {
      const lowerCaseOption = option.toLowerCase();
      if (!seen.has(lowerCaseOption)) {
        seen.set(lowerCaseOption, true); // Mark the option as seen
        return true; // Keep the first occurrence
      }
      return false; // Ignore duplicates
    });
  };

  const handleSubmit = async () => {
    if (!formTitle) {
      setTitleError("Title is Required");
      return;
    }
    if (!formDescription) {
      setDescriptionError("Description is Required");
      return;
    }

    setLoading(true);
    const updatedSections = sections.map((section) => {
      const updatedQuestions = section.questions.map((question) => {
        // Check if the question has options (not all question types do)
        if (question.options && question.options.length > 0) {
          return {
            ...question,
            // Remove duplicate options
            options: removeDuplicates(question.options),
          };
        }
        return question; // If no options, return the question unchanged
      });

      return {
        ...section,
        questions: updatedQuestions,
      };
    });

    try {
      let response;

      if (mode === "create") {
        response = await dispatch(
          createForm({
            data: updatedSections,
            title: formTitle,
            description: formDescription,
            is_template: false,
            reference_template: formId,
            type: formType,
            sub_type:
              formType === "custom"
                ? "custom"
                : formSubType
                ? formSubType
                : dummySubType,
          })
        );
      } else {
        response = await dispatch(
          updateForm({
            data: updatedSections,
            title: formTitle,
            description: formDescription,
            formId: formId,
            reference_template: formTemplate,
            type: formType,
            sub_type: formType === "custom" ? "custom" : formSubType,
          })
        );
      }

      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message:
              mode === "create"
                ? "Form created Succesfully."
                : "Form updated Succesfully.",
          })
        );
        navigate("/settings/formbuilder");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const addOption = (sectionId, questionId) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              return {
                ...q,
                options: [...q.options, ""], // Add empty option
              };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const removeOption = (sectionId, questionId, optionIndex) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              const updatedOptions = q.options.filter(
                (_, index) => index !== optionIndex
              );
              return { ...q, options: updatedOptions };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const updateQuestionOptions = (sectionId, questionId, newOptions) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              return { ...q, options: newOptions };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const handleFormTypeChange = (e) => {
    setFormSubType(e.target.value);
  };

  console.log(formSubType);
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ padding: "0 12px" }}>
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ alignItems: "flex-end !important" }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (mode === "edit") {
                navigate(-1);
              } else {
                navigate("/settings/formbuilder/select", {
                  state: {
                    title: title ? title : "",
                    description: description ? description : "",
                  },
                });
              }
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ alignItems: "flex-end !important" }}
          >
            {formType !== "custom" && (
              <FormControl sx={{ width: "fit-content" }}>
                <Select
                  displayEmpty
                  value={formSubType || dummySubType}
                  renderValue={(value) =>
                    value ? subTypesMapping[value] : "Select Form Library"
                  }
                  onChange={handleFormTypeChange}
                  sx={customStyles}
                  name="status"
                  className="basic-multi-select"
                  placeholder="Select Form Library"
                  classNamePrefix="select"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                >
                  {formSubTypes?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {subTypesMapping[item] || ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<VisibilityIcon />}
              onClick={onSubmit}
            >
              Preview Form
            </Button>
          </Box>
        </Box>
        <Box
          className="table-container"
          sx={{
            height: "calc(100vh - 170px)",
            overflowY: "auto",
            backgroundColor: "white",
            padding: "12px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" component="label">
                Form Title
              </Typography>
              <TextField
                value={formTitle}
                onChange={(e) => setFormTitle(e.target.value)}
                sx={{ backgroundColor: "white" }}
                required
                size="small"
              />
              {titleError && (
                <div className="form-error-message">{titleError}</div>
              )}
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="label">
                Form Description
              </Typography>
              <TextField
                value={formDescription}
                onChange={(e) => setFormDescription(e.target.value)}
                sx={{ backgroundColor: "white" }}
                required
                size="small"
              />
              {descriptionError && (
                <div className="form-error-message">{descriptionError}</div>
              )}
            </Grid>

            {!loading && (
              <Grid item xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                  {sections.map((section, sectionIndex) => (
                    <>
                      <Box
                        style={{
                          marginBottom: "16px",
                          padding: "8px 16px",
                          borderRadius: "8px ",
                          backgroundColor: "#ffffff",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          border: "none",
                          transition:
                            "transform 0.2s ease, box-shadow 0.2s ease",
                          "&:hover": {
                            transform: "scale(1.02)",
                            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        {sectionIndex === 0 && formType !== "custom" && (
                          <Typography
                            variant="label"
                            sx={{ color: "red", fontWeight: "bold" }}
                          >
                            This Section Is Not Editable
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            placeholder="Section Title"
                            value={section.name}
                            variant="standard"
                            onChange={(e) => {
                              const newName = e.target.value;
                              setSections((prevSections) =>
                                prevSections.map((sectionn) =>
                                  sectionn.id === section.id
                                    ? { ...sectionn, name: newName }
                                    : sectionn
                                )
                              );
                            }}
                            // sx={{ width: "250px" }}
                          />
                          {sections.length > 1 && (
                            <Button
                              onClick={() => removeSection(section.id)}
                              variant="standard"
                              color="error"
                            >
                              <CloseIcon sx={{ fontSize: "28px" }} />
                            </Button>
                          )}
                        </Box>
                        <Droppable
                          droppableId={section.id}
                          direction="vertical"
                          type="group"
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={
                                sectionIndex === 0 &&
                                formType !== "custom" &&
                                "form-disabled"
                              }
                            >
                              {section.questions.map((question, index) => (
                                <>
                                  {question.disabled &&
                                    formType === "custom" && (
                                      <Typography
                                        variant="label"
                                        sx={{
                                          color: "red",
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                        }}
                                      >
                                        This Question Is Not Editable
                                      </Typography>
                                    )}
                                  <Draggable
                                    key={question.id}
                                    draggableId={question.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          marginBottom: "3px",
                                          padding: "2px",
                                        }}
                                        className={
                                          question.disabled &&
                                          formType === "custom" &&
                                          "form-disabled"
                                        }
                                      >
                                        <Grid container alignItems="center">
                                          <Grid
                                            sx={{
                                              border: "1px solid #ccc",
                                              borderRadius: "4px",
                                              backgroundColor: "#fff",
                                            }}
                                            item
                                            xs={11}
                                          >
                                            <Grid
                                              container
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Grid item={1}>
                                                <Box sx={{ margin: "0 5px" }}>
                                                  <DragIndicatorIcon />
                                                  <IconButton
                                                    onClick={() =>
                                                      toggleQuestionDropdown(
                                                        question.id
                                                      )
                                                    }
                                                  >
                                                    {expandedQuestionId.includes(
                                                      question.id
                                                    ) ? (
                                                      <ArrowDropUpIcon />
                                                    ) : (
                                                      <ArrowDropDownIcon />
                                                    )}
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                              <Grid item xs={8}>
                                                <TextField
                                                  placeholder="Question Title"
                                                  value={question.label}
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    sx: {
                                                      "& .MuiOutlinedInput-notchedOutline":
                                                        {
                                                          border: "none",
                                                        },
                                                      borderRight:
                                                        "1px solid #ccc",
                                                      borderRadius: "0px",
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    const updatedSections =
                                                      sections.map((sec) => {
                                                        if (
                                                          sec.id === section.id
                                                        ) {
                                                          const updatedQuestions =
                                                            sec.questions.map(
                                                              (q) => {
                                                                if (
                                                                  q.id ===
                                                                  question.id
                                                                ) {
                                                                  return {
                                                                    ...q,
                                                                    label:
                                                                      e.target
                                                                        .value,
                                                                  };
                                                                }
                                                                return q;
                                                              }
                                                            );
                                                          return {
                                                            ...sec,
                                                            questions:
                                                              updatedQuestions,
                                                          };
                                                        }
                                                        return sec;
                                                      });
                                                    setSections(
                                                      updatedSections
                                                    );
                                                  }}
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Select
                                                  value={question.type}
                                                  sx={{
                                                    "& .MuiOutlinedInput-notchedOutline":
                                                      {
                                                        border: "none",
                                                      },
                                                    "& .MuiSelect-select": {
                                                      display: "flex",
                                                      alignItems: "center",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                                      {
                                                        borderColor: "#888",
                                                      },
                                                    "& .MuiInputBase-input": {
                                                      height: "20px",
                                                      minHeight: "0",
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    const newType =
                                                      e.target.value;
                                                    const updatedSections =
                                                      sections.map((sec) => {
                                                        if (
                                                          sec.id === section.id
                                                        ) {
                                                          const updatedQuestions =
                                                            sec.questions.map(
                                                              (q) => {
                                                                if (
                                                                  q.id ===
                                                                  question.id
                                                                ) {
                                                                  return {
                                                                    ...q,
                                                                    type: newType,
                                                                    ...(newType ===
                                                                      "Select" && {
                                                                      options:
                                                                        [],
                                                                    }), // Add options array if Select type
                                                                  };
                                                                }
                                                                return q;
                                                              }
                                                            );
                                                          return {
                                                            ...sec,
                                                            questions:
                                                              updatedQuestions,
                                                          };
                                                        }
                                                        return sec;
                                                      });
                                                    setSections(
                                                      updatedSections
                                                    );
                                                  }}
                                                  fullWidth
                                                >
                                                  {questionTypes.map((type) => {
                                                    return (
                                                      <MenuItem
                                                        key={type}
                                                        value={type}
                                                        disabled={
                                                          type ===
                                                          "Service Unit"
                                                        }
                                                      >
                                                        <ListItemIcon>
                                                          {
                                                            questionTypeIcons[
                                                              type
                                                            ]
                                                          }
                                                        </ListItemIcon>
                                                        <ListItemText
                                                          primary={type}
                                                        />
                                                      </MenuItem>
                                                    );
                                                  })}
                                                </Select>
                                              </Grid>
                                            </Grid>

                                            <Collapse
                                              in={expandedQuestionId.includes(
                                                question.id
                                              )}
                                              timeout="auto"
                                              unmountOnExit
                                              sx={{
                                                backgroundColor: "#f0f8fb",
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                p="4px"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={
                                                        question.required
                                                      }
                                                      onChange={() =>
                                                        handleRequiredChange(
                                                          section.id,
                                                          question.id
                                                        )
                                                      }
                                                    />
                                                  }
                                                  label="Required"
                                                  sx={{
                                                    ml: 2,
                                                    borderRight:
                                                      "1px solid #ccc",
                                                    paddingRight: 2,
                                                  }}
                                                />
                                                <ButtonGroup
                                                  variant="outlined"
                                                  aria-label="width options"
                                                >
                                                  <Button
                                                    onClick={() =>
                                                      handleWidthChange(
                                                        section.id,
                                                        question.id
                                                      )
                                                    }
                                                    variant={
                                                      question.width === 12
                                                        ? "contained"
                                                        : "outlined"
                                                    }
                                                  >
                                                    Full Width
                                                  </Button>
                                                  <Button
                                                    onClick={() =>
                                                      handleWidthChange(
                                                        section.id,
                                                        question.id
                                                      )
                                                    }
                                                    variant={
                                                      question.width === 6
                                                        ? "contained"
                                                        : "outlined"
                                                    }
                                                  >
                                                    Half Width
                                                  </Button>
                                                </ButtonGroup>
                                              </Box>

                                              {/* Dynamic Options Input for Select Type */}
                                              {question.type ===
                                                "Radio Buttons" && (
                                                <Box p={2}>
                                                  <Typography variant="subtitle2">
                                                    Options:
                                                  </Typography>
                                                  <Grid
                                                    container
                                                    mb={2}
                                                    spacing={2}
                                                  >
                                                    {question.options?.map(
                                                      (option, optionIndex) => (
                                                        <Grid
                                                          item
                                                          lg={4}
                                                          key={optionIndex}
                                                        >
                                                          <Box
                                                            display="flex"
                                                            gap="2px"
                                                          >
                                                            <TextField
                                                              value={option}
                                                              onChange={(e) => {
                                                                const updatedOptions =
                                                                  [
                                                                    ...question.options,
                                                                  ];
                                                                updatedOptions[
                                                                  optionIndex
                                                                ] =
                                                                  e.target.value;
                                                                updateQuestionOptions(
                                                                  section.id,
                                                                  question.id,
                                                                  updatedOptions
                                                                );
                                                              }}
                                                              size="small"
                                                              placeholder={`Option ${
                                                                optionIndex + 1
                                                              }`}
                                                              fullWidth
                                                            />
                                                            <IconButton
                                                              onClick={() =>
                                                                removeOption(
                                                                  section.id,
                                                                  question.id,
                                                                  optionIndex
                                                                )
                                                              }
                                                              color="error"
                                                            >
                                                              <CloseIcon />
                                                            </IconButton>
                                                          </Box>
                                                        </Grid>
                                                      )
                                                    )}
                                                  </Grid>
                                                  <Button
                                                    onClick={() =>
                                                      addOption(
                                                        section.id,
                                                        question.id
                                                      )
                                                    }
                                                    variant="outlined"
                                                    // startIcon={<AddIcon />}
                                                  >
                                                    Add Option
                                                  </Button>
                                                </Box>
                                              )}
                                            </Collapse>
                                          </Grid>
                                          {section.questions.length > 1 && (
                                            <Grid xs={1} item>
                                              <IconButton
                                                onClick={() =>
                                                  removeQuestion(
                                                    section.id,
                                                    question.id
                                                  )
                                                }
                                                sx={{
                                                  backgroundColor: "white",
                                                  color: "#036185",
                                                  padding: "10px",
                                                  borderRadius: "8px",
                                                  border: "1px solid #036185",
                                                  marginLeft: "20px",
                                                  width: "46px !important",
                                                  height: "46px !important",
                                                }}
                                              >
                                                <RiDeleteBinLine />
                                              </IconButton>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </div>
                                    )}
                                  </Draggable>
                                </>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {!(sectionIndex === 0 && formType !== "custom") && (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, width: "fit-content" }}
                            onClick={() => addQuestion(section.id)}
                            endIcon={<RiAddCircleLine />}
                            className={
                              sectionIndex === 0 && formType !== "custom"
                                ? "form-disabled primary-button"
                                : "primary-button"
                            }
                          >
                            Add Question
                          </Button>
                        )}
                      </Box>
                    </>
                  ))}
                </DragDropContext>

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1, width: "fit-content" }}
                  onClick={addSection}
                  className="primary-button"
                  endIcon={<RiAddCircleLine />}
                >
                  Add Section
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <CustomizedDialog
          className="modal-scroll"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          title={"Preview Form"}
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
        >
          <DialogContent>
            <FormRenderer sections={sections} />
          </DialogContent>
        </CustomizedDialog>
      </Box>
    </>
  );
}
