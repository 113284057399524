export const Status = {
  in_process: {
    color: "red",
    hover: "Pending Assignment",
    label: "IN PROCESS",
  },
  PENDING: { color: "red", hover: "Pending Assignment", label: "PENDING" },
  pending: { color: "red", hover: "Pending Assignment", label: "PENDING" },
  IN_REVIEW: {
    color: "orange",
    hover: "Report Under Review",
    label: "IN REVIEW",
  },
  COMPLETED: { color: "green", hover: "Review Completed", label: "COMPLETED" },
  completed: { color: "green", hover: "Review Completed", label: "COMPLETED" },
  open: { color: "orange", label: "Open" },
  closed: { color: "green", label: "Closed" },
  up_coming: { color: "orange", label: "Upcoming", hover: "Upcoming" },
};

export const JobTitle = {
  onsite_engineer: "Onsite Engineer",
  supervisor: "Supervisor",
  director: "Director",
};

export const ReportType = {
  hazard: "Hazard",
  incident: "Incident",
  maintenance: "Maintenance",
  general_observation: "General Observation",
  near_miss: "Near Miss",
};
