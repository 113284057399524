import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar,
  // Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Breadcrumbs,
  IconButton,
  useMediaQuery,
  Badge,
  Tooltip,
  DialogContent,
  DialogActions,
  Stack,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link, matchPath, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { PAGE_TITLE, PAGE_URL } from "../../constants/pageUrl.constant";
import { useDispatch } from "react-redux";
import { forgotPasswordThunk, logout } from "../../redux/slices/user.slice";
import "../../styles/landingpage.scss";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GoSignOut } from "react-icons/go";
import { RiLockPasswordFill } from "react-icons/ri";
import MenuIcon from "@mui/icons-material/Menu";
import { setSnackbar } from "../../redux/slices/common.slice";
import { IoNotificationsSharp } from "react-icons/io5";
import NotificationDrawer from "../layout/NotificationDrawer";
import {
  getAllNotification,
  sendFeedback,
} from "../../redux/slices/dashboard.slice";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CustomizedDialog from "./Dialog";
import * as yup from "yup";
import { useFormik } from "formik";
import { PhotoCamera } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { useSelector } from "react-redux";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};
const Header = ({ setOpenSideMenu, openSideMenu, isAdmin }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(false);
  const [notifications, setnotifications] = useState(null);
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [totalNotifications, settotalNotifications] = useState(0);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const headerTitle = useSelector((state) => state.common.headerTitle);

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    type: "query",
    screenshots: "",
  });
  const validationSchema = yup.object({
    title: yup.string().required("Please, Enter Title"),
    description: yup.string().required("Please, Enter Description"),
    screenshots: yup
      .array()
      .max(3, "You can only upload a maximum of 3 images.")
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (requestValue) => {
      setIsLoading(true);
      console.log(requestValue);
      const formData = new FormData();

      // Append all form fields
      formData.append("type", requestValue.type);
      formData.append("title", requestValue.title);
      formData.append("description", requestValue.description);
      requestValue.screenshots.forEach((file, index) => {
        formData.append(`images`, file);
      });

      try {
        let response = await dispatch(sendFeedback(formData));
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: `${requestValue.type} Submitted Succesfully!`,
            })
          );
          formik.resetForm();
          setImagePreviews([]); // Clear image previews
          setOpenFeedback(false);
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        setIsLoading(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });
  const [imagePreviews, setImagePreviews] = useState([]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (event) => {
    setAnchorElNotification((prev) => !prev);
    setOpenNotificationDrawer(true);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    Navigate(PAGE_URL.LOGIN);
  };

  const handleBilling = async () => {
    Navigate(PAGE_URL.BILLINGS_AND_PLANS);
    setAnchorEl(null);
  };

  const handleChangePassword = async () => {
    handleClose();
    try {
      let response = await dispatch(
        forgotPasswordThunk({
          email: JSON.parse(localStorage.getItem("user"))?.user?.email,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "4 digit verification code sent to email Successfully",
          })
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
  };

  const fetchNotifications = async () => {
    try {
      let response = await dispatch(
        getAllNotification({
          page: 1,
          // is_reviewed: false,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        setnotifications(payload?.data);
        settotalNotifications(
          payload?.data.filter((item) => !item.is_reviewed).length
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }

    // setnotifications(tempnotifications);
    // settotalNotifications(2);
  };

  useEffect(() => {
    fetchNotifications();
  }, [anchorElNotification]);

  const pageTitle = useMemo(() => {
    return PAGE_TITLE.find((item) => matchPath(item.path, location.pathname));
  }, [location]);

  const userName = JSON.parse(localStorage.getItem("user"))?.user?.first_name;

  const handleDrawerOpen = () => {
    setOpenSideMenu(!openSideMenu);
  };
  const handleFeedbackClick = () => {
    setOpenFeedback(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery("(max-width: 601px)");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (window.innerWidth < 601) {
      setOpenSideMenu(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <>
      <AppBar
        position="fixed"
        open={openSideMenu}
        className={`dashboard-navbar ${
          openSideMenu ? "sidebar-open" : "sidebar-closed"
        } `}
      >
        <Toolbar sx={{ py: 3 }}>
          {windowWidth < 601 && (
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ color: "#000", mx: 2, ...(open && { display: "none" }) }}
              className="menu-bar"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box variant="div" component="div" className="navbar-right">
            {pageTitle?.breadcrumb ? (
              <Breadcrumbs aria-label="breadcrumb" separator="|">
                {pageTitle?.breadcrumb?.map((ele, index) =>
                  ele.path ? (
                    <Link
                      key={index}
                      underline="hover"
                      to={ele.path}
                      className="breadcrumbsLink"
                    >
                      {ele.title}
                    </Link>
                  ) : (
                    <Typography
                      key={index}
                      variant="h3"
                      noWrap
                      className={`page-title_heading curruntPageLink`}
                    >
                      {ele?.title ? ele?.title : headerTitle}
                    </Typography>
                  )
                )}
              </Breadcrumbs>
            ) : (
              <Typography
                variant="h3"
                color="#FFFFFF"
                noWrap
                className="page-title_heading curruntPageLink"
              >
                {pageTitle?.title}
              </Typography>
            )}
            <Box variant="div" component="div" className="user-info">
              <Tooltip title="Give Feedback or Send Query">
                <IconButton aria-label="feedback" onClick={handleFeedbackClick}>
                  <FeedbackIcon
                    style={{
                      fontSize: "1.5rem",
                      fill: "var(--color-theme-secondary)", // Align with your theme color
                    }}
                  />
                </IconButton>
              </Tooltip>

              <IconButton aria-label="cart" onClick={handleNotificationClick}>
                <Badge
                  badgeContent={totalNotifications}
                  max={999}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "red", // Set the background color of the badge
                      color: "white", // Optional: Set the text color inside the badge
                    },
                  }}
                >
                  <IoNotificationsSharp
                    fill="var(--color-theme-secondary)"
                    style={{ fontSize: "1.5rem" }}
                  />
                </Badge>
              </IconButton>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disableRipple
              >
                <span className="mr-1" mr={2}>
                  {userName}
                </span>{" "}
                <RiArrowDownSLine />
              </Button>
              <Menu
                id="basic-menu"
                className="user-info-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleBilling()} key={"3"}>
                  <FaMoneyBillTrendUp />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Billing Overview
                  </Typography>
                </MenuItem>
                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                <MenuItem
                  className="user-info-menu-item"
                  key={"1"}
                  onClick={handleChangePassword}
                >
                  <RiLockPasswordFill />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Change Password
                  </Typography>
                </MenuItem>
                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                <MenuItem onClick={() => handleLogout()} key={"2"}>
                  <GoSignOut />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Sign Out
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <NotificationDrawer
        openDrawer={openNotificationDrawer}
        setOpenDrawer={setOpenNotificationDrawer}
        notifications={notifications}
        fetchNotifications={fetchNotifications}
        totalNotifications={totalNotifications}
        setnotifications={setnotifications}
        settotalNotifications={settotalNotifications}
      />

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Support & FeedBack"}
        open={openFeedback}
        handleClose={() => {
          setOpenFeedback(false);
        }}
      >
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select Type
                  </Typography>
                  <FormControl component="fieldset" sx={{ mt: 1 }}>
                    <RadioGroup
                      row
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="query"
                        control={<Radio size="small" />}
                        sx={{ width: "fit-content !important" }}
                        label="Support Request"
                      />
                      <FormControlLabel
                        value="feedback"
                        control={<Radio size="small" />}
                        sx={{ width: "fit-content !important" }}
                        label="Feedback"
                      />
                    </RadioGroup>
                  </FormControl>

                  {formik.touched.type && formik.errors.type && (
                    <div className="form-error-message">
                      {formik.errors.type}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Title
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder={`Enter ${formik.values.type} Title`}
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="form-error-message">
                      {formik.errors.title}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder={`Enter ${formik.values.type} Description`}
                    size="small"
                    multiline
                    rows={5}
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label" gutterBottom>
                    Add Screenshots (up to 3 images)
                  </Typography>

                  {/* Upload Button */}
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<PhotoCamera />}
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Upload Images
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      hidden
                      onChange={(event) => {
                        const files = Array.from(event.target.files);

                        if (files.length > 3) {
                          formik.setFieldError(
                            "screenshots",
                            "You can only upload a maximum of 3 images."
                          );
                          console.log(formik.errors);
                          return;
                        }

                        const previews = files.map((file) => ({
                          file,
                          url: URL.createObjectURL(file),
                        }));

                        // Update the state with the new image previews
                        setImagePreviews(previews);
                        formik.setFieldValue("screenshots", files); // Store multiple files in Formik state
                      }}
                    />
                  </Button>
                  {formik.errors.screenshots && (
                    <div className="form-error-message">
                      {formik.errors.screenshots}
                    </div>
                  )}
                  {/* Display Image Previews */}
                  {imagePreviews?.length > 0 && (
                    <Grid container spacing={2}>
                      {imagePreviews.map((preview, index) => (
                        <Grid item xs={3} key={index}>
                          <Box
                            sx={{
                              position: "relative",
                              borderRadius: "8px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={preview.url}
                              alt={`screenshot-${index}`}
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />

                            {/* Delete Icon */}
                            <Tooltip title="Remove Image">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const updatedPreviews = imagePreviews.filter(
                                    (_, i) => i !== index
                                  );
                                  setImagePreviews(updatedPreviews); // Update state without the removed image
                                  formik.setFieldValue(
                                    "screenshots",
                                    updatedPreviews.map(
                                      (preview) => preview.file
                                    )
                                  ); // Update Formik state
                                }}
                                sx={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                                  },
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Box className="modal-footers">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                className="primary-button"
                disabled={isLoading}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                className="secondary-button"
                disabled={isLoading}
                onClick={() => {
                  setOpenFeedback(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default Header;
