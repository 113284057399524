import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  documentAnalyse,
  getFormsByType,
  getFormTypes,
} from "../../../redux/slices/forms.slice";
import { IoMdCreate, IoMdDocument, IoMdEye } from "react-icons/io";
import { DiDatabase } from "react-icons/di";
import CustomizedDialog from "../Dialog";
import FormRenderer from "../settings/formTemplates/formRenderer";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { setSnackbar } from "../../../redux/slices/common.slice";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Forms() {
  const [selectedFormType, setSelectedFormType] = useState("audit");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [typeloading, setTypeLoading] = useState(false);
  const [formTypes, setFormTypes] = useState([]);
  const [forms, setForms] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedForm, setSelectedForm] = useState(false);
  const [imageData, setImageData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setSelectedFormType(formTypes[newValue].type);
    setValue(newValue);
  };

  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        setFormTypes(payload?.data);
      }
    } catch (e) {}
    setLoading(false);
  };

  const fetchFormsByType = async () => {
    setTypeLoading(true);
    try {
      const response = await dispatch(
        getFormsByType({ formType: selectedFormType })
      );
      const { payload } = response;
      if (payload?.status) {
        setForms(payload?.data);
      }
    } catch (e) {}
    setTypeLoading(false);
  };
  useEffect(() => {
    fetchAllFormTypes();
  }, []);

  useEffect(() => {
    fetchFormsByType();
  }, [selectedFormType]);

  const handleClick = (form) => {
    if (selectedFormType === "employee_induction") {
      setOpenImageModal(true);
      setSelectedForm(form);
    } else navigate(`/forms/${form.id}/${selectedFormType}/create`);
  };

  const handleImageAnalysis = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Append each file to the form data
      for (let i = 0; i < imagePreviews.length; i++) {
        formData.append("images", imagePreviews[i]?.file); // 'images' is the key for the backend
      }

      let response = await dispatch(documentAnalyse(formData));
      const { payload } = response;
      if (payload && payload.status) {
        setImageData(payload.data);
        navigate(`/forms/${selectedForm?.id}/${selectedFormType}/create`, {
          state: {
            data: payload.data,
          },
        });
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={2}>
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
        >
          {formTypes?.map((item, index) => (
            <Tab label={item?.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

      <Grid container spacing={4}>
        {typeloading ? (
          <Box
            sx={{ height: "calc(100vh - 200px)", margin: "auto" }}
            className="center"
          >
            <CircularProgress />
          </Box>
        ) : forms.length > 0 ? (
          forms.map((form) => (
            <Grid item xs={12} sm={6} md={4} key={form.id}>
              <Card
                sx={{
                  maxHeight: 250,
                  height: "100%",
                  position: "relative",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-2px)", // Moves the card up by 10px
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Adds shadow for emphasis
                  },
                }}
                onClick={() => handleClick(form)}
              >
                <CardContent>
                  <Box
                    className="space-between"
                    sx={{
                      paddingBottom: "10px",
                    }}
                  >
                    <IconButton className="New-Chat">
                      <IoMdDocument color="#CA9688" />
                    </IconButton>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="Preview Form">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPreview(true);
                            setSelectedFormId(form);
                          }}
                          className="New-Chat"
                        >
                          <IoMdEye color="#CA9688" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#036185",
                      fontSize: "16px",
                      marginTop: "10px",
                      fontWeight: "700",
                    }}
                    variant="h6"
                  >
                    {form.title}
                  </Typography>
                  <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                  <Typography
                    sx={{ fontSize: "14px", color: "#4F4F4F" }}
                    variant="h6"
                  >
                    {`${
                      form.description.length > 115
                        ? form.description.substring(0, 115) + "..."
                        : form.description
                    }`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            className="no-data-found"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 1,
            }}
          >
            <Stack spacing={0} justifyContent="center" alignItems="center">
              <DiDatabase size={50} />
              <span>No data found</span>
            </Stack>
          </Box>
        )}
      </Grid>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer formId={selectedFormId?.id} />
        </DialogContent>
      </CustomizedDialog>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Upload Documents (images)"}
        open={openImageModal}
        handleClose={() => setOpenImageModal(false)}
      >
        <DialogContent>
          <Box>
            {/* Display Image Previews */}
            <Grid container spacing={2}>
              {imagePreviews?.length === 0 ? (
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px", // Set a height for visual structure
                      border: "2px dashed rgba(0, 0, 0, 0.2)", // Dashed border for empty state
                      borderRadius: "8px",
                      padding: "16px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                      gap: 2,
                    }}
                  >
                    <InsertPhotoIcon
                      sx={{ fontSize: 48, color: "rgba(0, 0, 0, 0.3)" }}
                    />
                    <Typography variant="h6" color="textSecondary">
                      No images selected
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                imagePreviews.map((preview, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                        height: "300px", // Fixed height for consistency
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
                        transition: "box-shadow 0.3s ease-in-out",
                      }}
                    >
                      <img
                        src={preview.url}
                        alt={`screenshot-${index}`}
                        style={{
                          maxWidth: "100%", // Ensures it doesn’t overflow horizontally
                          maxHeight: "100%", // Ensures it doesn’t overflow vertically
                          objectFit: "contain", // Adjust to contain within the box without distortion
                          borderRadius: "8px",
                        }}
                      />

                      {/* Delete Icon */}
                      <Tooltip title="Remove Image">
                        <IconButton
                          size="small"
                          onClick={() => {
                            const updatedPreviews = imagePreviews.filter(
                              (_, i) => i !== index
                            );
                            setImagePreviews(updatedPreviews); // Update state without the removed image
                          }}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.8)",
                            },
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="modal-footers">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                component="label"
                className="primary-button"
                disabled={loading}
                startIcon={<PhotoCamera />}
                // onClick={formik.handleSubmit}
              >
                {loading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Upload Images
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={(event) => {
                    const files = Array.from(event.target.files);

                    const previews = files.map((file) => ({
                      file,
                      url: URL.createObjectURL(file),
                    }));

                    // Update the state with the new image previews
                    setImagePreviews(previews);
                  }}
                />
              </Button>
              {imagePreviews.length > 0 && (
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={loading}
                  onClick={handleImageAnalysis}
                >
                  Save & Next
                </Button>
              )}

              <Button
                variant="outlined"
                className="secondary-button"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `/forms/${selectedForm?.id}/${selectedFormType}/create`
                  )
                }
              >
                Skip
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </Box>
  );
}
