// App.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  createServiceUnit,
  getAllServiceUnitType,
  getServiceUnitById,
  updateServiceUnit,
} from "../../../redux/slices/serviceUnit.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Loader from "../Loader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const CreateServiceUnit = ({ mode }) => {
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const location = useLocation();
  const { type, questions } = location.state || {};
  const [selectedQuestions, setSelectedQuestions] = useState(questions);
  const [selectedType, setSelectedType] = useState(type);
  const [serviceTypes, setServiceTypes] = useState();
  const [serviceUnitTypes, setServiceUnitTypes] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceUnitId } = useParams();

  const handleProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicFile(URL.createObjectURL(file));
      const base64 = await toBase64(file);
      let profilePicture = {
        base: removeUntilFirstComma(base64),
        extension: file.type.split("/")[1], // Get the file extension from type
        name: file.name, // Store the file name
      };
      setProfilePic(profilePicture);
    }
  };
  function removeUntilFirstComma(str) {
    const commaIndex = str.indexOf(",");
    if (commaIndex !== -1) {
      return str.substring(commaIndex + 1); // +1 to skip the comma itself
    }
    return str; // return original string if no comma is found
  }

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  //profile_picture
  const handleAttachmentsUpload = async (event) => {
    const files = Array.from(event.target.files);

    const newFilesData = [];
    let id = 1;
    for (const file of files) {
      const base64 = await toBase64(file);
      newFilesData.push({
        base: removeUntilFirstComma(base64),
        extension: file.type.split("/")[1], // Get the file extension from type
        file_name: file.name, // Store the file name
        id: id++,
      });
    }

    const newFiles = [...attachments, ...newFilesData];

    setAttachments(newFiles);
  };
  const handleAttachmentDelete = (index) => {
    setAttachments((prev) => prev.filter((item, idx) => idx !== index));
  };

  const fetchServiceUnitDetailsById = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getServiceUnitById({
          id: serviceUnitId,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setDisplayName(payload?.data?.name);
        setToken(payload?.token);
        setSelectedQuestions(payload?.data?.service_unit_details_json);
        setSelectedType({
          value: payload?.data?.type?.id,
          label: payload?.data?.type?.name,
        });
        setAttachments(payload?.data?.documents || []);
        setProfilePicFile(
          `${payload?.data?.profile_picture?.img_url}?${payload?.token}`
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (mode === "edit") fetchServiceUnitDetailsById();
  }, [mode]);

  const validateForm = () => {
    const newErrors = {};
    if (!displayName) {
      newErrors["displayName"] = `This Field is Required`;
    }
    selectedQuestions.forEach((question) => {
      if (question.required && !question.answer) {
        newErrors[question.id] = `This Field is Required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };
  const handleSubmit = async () => {
    const data = {
      type: selectedType?.value,
      name: displayName,
      service_unit_details_json: selectedQuestions,
    };
    if (!validateForm()) return; // Prevent submission if validation fails
    if (profilePic) {
      data.profile_picture = {
        base: profilePic.base || null,
        extension: profilePic.extension,
        file_name: profilePic.file_name,
        url: profilePic.url || null,
      };
    }

    // if (attachments.length > 0) {
    data.documents = attachments?.map((item) => ({
      base: item.base || null,
      extension: item.extension,
      file_name: item.file_name,
      url: item.url || null,
    }));
    // }

    setLoading(true);
    try {
      let response;
      if (mode === "create") {
        response = await dispatch(
          createServiceUnit({
            data,
          })
        );
      } else {
        response = await dispatch(
          updateServiceUnit({
            data,
            id: serviceUnitId,
          })
        );
      }

      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message:
              mode === "create"
                ? "Service Unit created Succesfully."
                : "Service Unit updated Succesfully.",
          })
        );

        navigate(-1);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }
    setLoading(false);
  };
  const handleInputChange = (id, value) => {
    setSelectedQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === id ? { ...q, answer: value } : q))
    );
  };

  const fetchAllServiceType = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnitType({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitTypes(
          payload?.data?.map((item) => ({ label: item.name, value: item.id }))
        );
        setServiceTypes(payload?.data);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };
  useEffect(() => {
    if (mode === "edit") fetchAllServiceType();
  }, [mode]);
  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
    setSelectedQuestions(
      serviceTypes
        ?.find((item) => item.id === selectedOption.value)
        ?.service_unit_json.map((question) => ({
          ...question,
          answer: "",
        })) || []
    );
  };
  return (
    <Box>
      {loading && <Loader />}
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box className="box-container" sx={{ height: "calc(100vh - 180px)" }}>
            <Stack alignItems="center" spacing={2}>
              <Typography
                sx={{
                  color: "#00446B !important",
                  fontSize: "18px !important",
                  fontWeight: "500 !important",
                }}
                mb={2}
              >
                Upload Profile Picture
              </Typography>
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <Avatar
                  src={`${profilePicFile}`}
                  sx={{
                    width: 120,
                    height: 120,
                    border: "2px solid #ddd",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    backgroundColor: "#64bcd2",
                  }}
                />

                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "var(--color-theme-secondary);",
                    color: "#fff",
                    borderRadius: "50%",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <PhotoCameraIcon />
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleProfilePicUpload}
                  />
                </IconButton>
              </Box>

              {/* Upload Attachments Button */}
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{
                  borderColor: "var(--color-theme-secondary)",
                  color: "var(--color-theme-secondary)",
                  "&:hover": {
                    backgroundColor: "var(--color-theme-secondary)",
                    color: "#fff",
                  },
                }}
              >
                Upload Documents
                <input
                  hidden
                  type="file"
                  multiple
                  onChange={handleAttachmentsUpload}
                />
              </Button>

              {/* List of Uploaded Attachments */}
              {attachments?.length > 0 && (
                <Box>
                  <List>
                    {attachments.map((file, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleAttachmentDelete(index)}
                          >
                            <DeleteIcon
                              sx={{ color: "var(--color-theme-secondary)" }}
                            />
                          </IconButton>
                        }
                      >
                        <ListItemIcon>
                          <InsertDriveFileIcon />
                        </ListItemIcon>
                        {file.url ? (
                          <Link to={`${file.url}?${token}`} target="_blank">
                            <ListItemText primary={file.file_name} />
                          </Link>
                        ) : (
                          <ListItemText primary={file.file_name} />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box className="box-container" sx={{ height: "calc(100vh - 180px)" }}>
            {/* <Box sx={{ flex: 1, overflowY: "auto", padding: "24px" }}> */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" component="label">
                  Display Name <span style={{ color: "red" }}> *</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter Display Name"
                  value={displayName}
                  name={"displayName"}
                  onChange={(e) => {
                    const { name } = e.target;
                    setErrors((prevErrors) => {
                      const newErrors = { ...prevErrors };
                      delete newErrors[name];
                      return newErrors;
                    });
                    setDisplayName(e.target.value);
                  }}
                  error={errors["displayName"]}
                />
                {errors["displayName"] && (
                  <div className="form-error-message">
                    {errors["displayName"]}
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" component="label">
                  Service Unit Type <span style={{ color: "red" }}> *</span>
                </Typography>
                {mode === "edit" && (
                  <>
                    <Select
                      options={serviceUnitTypes}
                      value={selectedType}
                      onChange={handleTypeChange}
                      styles={customStyles}
                      placeholder="Select Type"
                    />
                  </>
                )}

                {mode === "create" && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter Type"
                    value={type.label}
                    name={"type"}
                    disabled={true}
                  />
                )}
              </Grid>
              {selectedQuestions?.map((question) => (
                <Grid item xs={question.width} key={question.id}>
                  <Typography variant="body1" component="label">
                    {question.label}
                    {question.required && (
                      <span style={{ color: "red" }}> *</span>
                    )}
                  </Typography>

                  {question.type === "Text Field" && (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Your answer here"
                        required={question.required}
                        name={question.id}
                        value={question.answer || ""}
                        onChange={(e) => {
                          const { name } = e.target;
                          setErrors((prevErrors) => {
                            const newErrors = { ...prevErrors };
                            delete newErrors[name];
                            return newErrors;
                          });
                          handleInputChange(question.id, e.target.value);
                        }}
                        error={errors[question.id]}
                      />
                      {errors[question.id] && (
                        <div className="form-error-message">
                          {errors[question.id]}
                        </div>
                      )}
                    </>
                  )}

                  {question.type === "Date" && (
                    <>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        size="small"
                        required={question.required}
                        value={question.answer || ""}
                        name={question.id}
                        onChange={(e) => {
                          const { name } = e.target;
                          setErrors((prevErrors) => {
                            const newErrors = { ...prevErrors };
                            delete newErrors[name];
                            return newErrors;
                          });
                          handleInputChange(question.id, e.target.value);
                        }}
                        error={errors[question.id]}
                      />
                      {errors[question.id] && (
                        <div className="form-error-message">
                          {errors[question.id]}
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
            {/* </Box> */}
          </Box>
        </Grid>
      </Grid>
      <Box className="footer">
        <Button className="secondary-footer-btn" onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button className="primary-footer-btn" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CreateServiceUnit;
