import React, { useState, useEffect } from "react";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import "../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import CustomizedDialog from "../Dialog";
import {
  createServiceUnitSchedule,
  getAllServiceUnit,
  updateServiceUnitSchedule,
} from "../../../redux/slices/serviceUnit.slice";
import { getAllUsersThunk } from "../../../redux/slices/tenant.slice";
import { getAllForm } from "../../../redux/slices/forms.slice";
import { useParams } from "react-router-dom";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};
const frequencyOptions = [
  { label: "None", value: "NONE" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const dayOptions = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

const dayOfWeekOptions = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
];
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px",
  }),
  menuList: (base) => ({
    ...base,
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const CreateInspectionSchedule = (
  { isOpenDrawer, setIsOpenDrawer, drawerState, values, fetchAlltasks },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    assigned_users: [],
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    frequency: { label: "None", value: "NONE" },
    inspection_form: null,
    service_unit: null,
    weekday: [],
    month_day: 1,
  });
  const [users, setUsers] = useState([]);
  const [serviceUnitOptions, setServiceUnitOptions] = useState([]);
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [inspectionForm, setInspectionForm] = useState([]);

  const { serviceUnitId } = useParams();
  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    // Due date is required
    start_date: yup.date().required("Schedule Start Date is required"),

    end_date: yup.date().required("Schedule End Date is required"),

    // Service Unit is required
    service_unit: yup
      .object()
      .shape({
        value: yup.string().required("Service Unit is required"),
        label: yup.string(),
      })
      .nullable()
      .required("Service Unit is required"),
    inspection_form: yup
      .object()
      .shape({
        value: yup.string().required("Template is required"),
        label: yup.string(),
      })
      .nullable()
      .required("Service Unit is required"),
    // At least one user must be selected (Multi-Select validation)
    assigned_users: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .min(1, "At least one user must be selected")
      .required("Field is required"),
    // Description is optional
    description: yup.string(),

    weekday: yup.array().when("frequency.value", {
      is: "WEEKLY",
      then: (schema) => schema.min(1, "Weekday is required "),
      otherwise: (schema) => schema.notRequired(),
    }),

    month_day: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .when("frequency.value", {
        is: "MONTHLY",
        then: (schema) => schema.required("Month day is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (valuess) => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateServiceUnitSchedule({
                data: {
                  ...valuess,
                  service_unit: valuess.service_unit.value,
                  frequency: valuess.frequency.value,
                  inspection_form: valuess.inspection_form.value,
                  month_day: valuess.month_day
                    ? valuess.month_day.value
                    : undefined,
                  assigned_users: valuess.assigned_users?.map(
                    (item) => item.value
                  ),
                  weekday:
                    valuess.weekday && valuess.weekday.length > 0
                      ? valuess.weekday?.map((item) => item.value)
                      : undefined,
                },
                id: values?.id,
              })
            : createServiceUnitSchedule({
                data: {
                  ...valuess,
                  service_unit: valuess.service_unit.value,
                  frequency: valuess.frequency.value,
                  inspection_form: valuess.inspection_form.value,
                  month_day: valuess.month_day
                    ? valuess.month_day.value
                    : undefined,
                  assigned_users: valuess.assigned_users?.map(
                    (item) => item.value
                  ),
                  weekday:
                    valuess.weekday && valuess.weekday.length > 0
                      ? valuess.weekday?.map((item) => item.value)
                      : undefined,
                },
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Inspection Schedule Edited Succesfully."
                  : "Inspection Schedule Created Succesfully.",
            })
          );
          setIsOpenDrawer(false);
          fetchAlltasks();
          formik.resetForm();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };

  const fetchInspectionForms = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ type__in: ["inspection"] }),

          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setInspectionForm(
          payload?.data?.map((item) => ({ label: item.title, value: item.id }))
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setIsLoading(false);
  };
  useEffect(() => {
    fetchInspectionForms();
  }, []);

  const fetchAllUsers = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 100000000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));

      setUsers(temp);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(
    () => {
      fetchAllUsers();
    }, // eslint-disable-next-line
    []
  );

  const fetchAllserviceUnit = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
        const temp = payload?.data?.find(
          (item, idx) => item.id === +serviceUnitId
        );
        if (temp && formik.values.service_unit === null)
          formik.values.service_unit = {
            label: temp.name,
            value: temp.id,
          };
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, []);

  const setTaskValues = () => {
    let data = {
      title: values?.title,
      description: values?.description,
      service_unit: serviceUnitOptions?.find(
        (item, value) => item.value === +values?.service_unit?.id
      ),
      frequency: frequencyOptions?.find(
        (item, value) => item.value === values?.frequency
      ),
      month_day: values?.month_day
        ? dayOptions?.find((item, value) => item.value === +values?.month_day)
        : null,
      inspection_form: inspectionForm?.find(
        (item, value) => item.value === +values?.inspection_form
      ),

      assigned_users: values?.assigned_users?.map((user) => ({
        value: user.id,
        label: user.first_name + " " + user.last_name,
      })),
      weekday: values.weekday
        ? dayOfWeekOptions.filter((item) => values.weekday.includes(item.value))
        : [],
      start_date: values.start_date
        ? new Date(values.start_date).toISOString().split("T")[0]
        : "",
      end_date: values.end_date
        ? new Date(values.end_date).toISOString().split("T")[0]
        : "",
    };
    setInitialValues(data);
    formik.setValues(data);
  };
  useEffect(() => {
    if (drawerState !== "create") setTaskValues();
  }, [values, serviceUnitOptions]);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Inspection Schedule Detail"
            : drawerState === "create"
            ? "Start Inspection Schedule"
            : "Edit Inspection Schedule"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form className={drawerState === "view" && "form-disabled"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Title
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Title"
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="form-error-message">
                      {formik.errors.title}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    multiline
                    rows="3"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Schedule Start Date
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    type="date"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Select Schedule Start Date"
                    size="small"
                    name="Schedule Start Date"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      }, // Restrict past dates
                    }}
                    {...formik.getFieldProps("start_date")}
                  />
                  {formik.touched.start_date && formik.errors.start_date && (
                    <div className="form-error-message">
                      {formik.errors.start_date}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Schedule End Date
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    type="date"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Select Scehdule End Date"
                    size="small"
                    name="Schedule End Date"
                    InputProps={{
                      inputProps: {
                        min: formik.values.start_date
                          ? new Date(formik.values.start_date)
                              .toISOString()
                              .split("T")[0]
                          : undefined, // Fallback to undefined if start_date is not set
                      },
                    }}
                    {...formik.getFieldProps("end_date")}
                  />
                  {formik.touched.end_date && formik.errors.end_date && (
                    <div className="form-error-message">
                      {formik.errors.end_date}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Repeat Type
                  </Typography>
                  <Selectt
                    options={frequencyOptions}
                    value={formik?.values?.frequency}
                    onChange={(e) => {
                      formik.setFieldValue("frequency", e);
                    }}
                    styles={customStyles}
                    placeholder="Select Repeat Type"
                    menuPosition="fixed"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.frequency && formik.errors.frequency && (
                    <div className="form-error-message">
                      {formik.errors.frequency}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                {formik?.values?.frequency?.value === "WEEKLY" && (
                  <>
                    <Typography variant="body1" component="label">
                      Select a day of the week
                    </Typography>

                    <Selectt
                      styles={customStyles}
                      isMulti
                      size="small"
                      fullWidth
                      options={dayOfWeekOptions}
                      value={formik?.values?.weekday}
                      onChange={(e) => {
                        formik.setFieldValue("weekday", e);
                      }}
                      classNamePrefix="select"
                      placeholder={"Select days of the week"}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      menuPosition="fixed"
                      menuPlacement="top"
                    ></Selectt>
                    {formik.touched.weekday && formik.errors.weekday && (
                      <div className="form-error-message">
                        {formik.errors.weekday}
                      </div>
                    )}
                  </>
                )}
                {formik?.values?.frequency?.value === "MONTHLY" && (
                  <>
                    <Typography variant="body1" component="label">
                      Select a day
                    </Typography>
                    <Selectt
                      options={dayOptions}
                      value={formik?.values?.month_day}
                      onChange={(e) => {
                        formik.setFieldValue("month_day", e);
                      }}
                      styles={customStyles}
                      placeholder="Select Service Unit"
                      menuPosition="fixed"
                      menuPlacement="top"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      classNamePrefix="select"
                    />
                    {formik.touched.month_day && formik.errors.month_day && (
                      <div className="form-error-message">
                        {formik.errors.month_day}
                      </div>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select Service Unit
                  </Typography>
                  <Selectt
                    options={serviceUnitOptions}
                    value={formik?.values?.service_unit}
                    onChange={(e) => {
                      formik.setFieldValue("service_unit", e);
                    }}
                    styles={customStyles}
                    placeholder="Select Service Unit"
                    menuPosition="fixed"
                    menuPlacement="top"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.service_unit &&
                    formik.errors.service_unit && (
                      <div className="form-error-message">
                        {formik.errors.service_unit}
                      </div>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select Template
                  </Typography>
                  <Selectt
                    options={inspectionForm}
                    value={formik?.values?.inspection_form}
                    onChange={(e) => {
                      formik.setFieldValue("inspection_form", e);
                    }}
                    styles={customStyles}
                    placeholder="Select Inspection Form"
                    menuPosition="fixed"
                    menuPlacement="top"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.inspection_form &&
                    formik.errors.inspection_form && (
                      <div className="form-error-message">
                        {formik.errors.inspection_form}
                      </div>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select User (s)
                  </Typography>
                  <Selectt
                    styles={customStyles}
                    isMulti
                    size="small"
                    fullWidth
                    options={users}
                    value={formik?.values?.assigned_users}
                    onChange={(e) => {
                      formik.setFieldValue("assigned_users", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Users..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPosition="fixed"
                    menuPlacement="top"
                  ></Selectt>
                  {formik.touched.assigned_users &&
                    formik.errors.assigned_users && (
                      <div className="form-error-message">
                        {formik.errors.assigned_users}
                      </div>
                    )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {drawerState !== "view" && (
          <DialogActions>
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        )}
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status ( IN REVIEW => REVIEW COMPLETED )"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{formik?.values?.title}</b>{" "}
              as reviewed and completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={() => {
                formik.setFieldValue("clickedButton", "ReviewComplete");
                formik.handleSubmit();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateInspectionSchedule;
